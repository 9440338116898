@import url("https://fonts.googleapis.com/css2?family=DM+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Francois+One&family=Gothic+A1:wght@100;200;300;400;500;600;700;800;900&family=Hina+Mincho&display=swap");

* {
  padding: 0;
  margin: 0;
}

body {
  margin: 0;
  font-size: 14px;
  font-weight: 400;
  color: #000;
  overflow-x: hidden;
  font-family: DM Sans, sans-serif;
  background-color: #e2e2e2;
  box-sizing: border-box;
}

:root {
  --light-pink: #ffc0bc;
  --light-blue: #b5c6ff;
  --main-black: #1c1e24;
  --primary-black: #1f1f1f;
  --main-blue: #1039c2;
  --dark-gray: #575757;
  --light-gray: #8c8c8c;
  --primary-white: #d9d9d9;
  --primary-violet: #024c87;
  --primary-blue: #576bad;
  --secondary-blue: #4f5a80;
  --main-white: #fff;
}

html {
  overflow-x: hidden;
}
a,
a:hover {
  text-decoration: none;
  transition: 0.3s;
  color: inherit;
}

a:hover {
  color: inherit;
}

.cursor-pointer {
  cursor: pointer;
}

/****************************************************************  Sidebar Page CSS *******************************************************/

.sidebar_container {
  background-color: var(--main-black);
  width: 100%;
  padding: 20px 10px;
  position: relative;
  height: 100vh;
  padding-left: 18px;
}

.sidebar_container .sidebar_logo {
  margin-bottom: 20px;
}

.sidebar_container .sidebar_logo img {
  width: 60%;
}

.sidebar_container .sidebar_list ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
.sidebar_container .sidebar_list ul li {
  padding: 10px 18px;
  width: 100%;
  color: var(--primary-white);
}

.sidebar_container .sidebar_list ul li a {
  display: flex;
  align-items: center;
  gap: 12px;
  color: var(--main-white);
  text-align: center;
  font-family: "DM Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 144.5%; /* 23.12px */
  text-transform: capitalize;
}

.sidebar_container .sidebar_list ul .isactive {
  background-color: var(--primary-violet);
  border-radius: 4px;
  position: relative;
}

.sidebar_container .sidebar_list ul .isactive::before {
  content: "";
  position: absolute;
  left: 3%;
  padding: 0px 2px;
  width: 1%;
  height: 60%;
  border-radius: 3px;
  background-color: var(--main-white);
}

.sidebar_profile_box {
  position: absolute;
  bottom: 3%;
  left: 0;
  width: 100%;
  padding: 10px;
  padding-left: 18px;
}

.sidebar_container .sidebar_profile_tab {
  display: flex;
  align-items: center;
  background-color: var(--primary-violet);
  padding: 10px;
  width: 100%;
  justify-content: space-between;
  border-radius: 6px;
  cursor: pointer;
}
.sidebar_container .sidebar_profile_tab span {
  color: #fff;
  font-size: 18px;
}
.sidebar_container .sidebar_profile_tab svg {
  fill: var(--main-white);
  font-size: 22px;
}

.sidebar_container .sidebar_profile_tab img {
  width: 35px;
  height: 35px;
  border-radius: 50%;
}

.sidebar_container .sidebar_profile_tab h3 {
  color: var(--main-white);
  font-family: "DM Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 24px */
  text-transform: capitalize;
  margin: 0;
}
.sidebar_container .sidebar_profile_tab h4 {
  color: var(--main-white);
  font-family: "DM Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 21px */
}

/**************************************************** Header CSS ********************************************************************/

.header_container {
  background-color: var(--main-white);
  border-bottom: 1px solid #e2e2e2;
  padding: 12px 22px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.header_container .header_breadcrumb {
  display: flex;
  gap: 10px;
}
.header_container .header_breadcrumb a {
  display: flex;
  align-items: center;
  gap: 4px;
  color: #8c8c8c;
  text-align: center;
  font-family: "DM Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 144.5%; /* 23.12px */
  text-transform: capitalize;
}

.header_container .header_profile_details {
  display: flex;
  align-items: center;
  gap: 22px;
  padding-right: 15px;
}
.header_container .header_profile_details .header_profile_details_img {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  object-fit: cover;
}
.header_container .header_profile_details svg {
  scale: 1.2;
}

.header_container .header_profile_details .header_profile_bellicon {
  position: relative;
  cursor: pointer;
}
.header_container
  .header_profile_details
  .header_profile_bellicon.active::before {
  position: absolute;
  height: 12px;
  width: 12px;
  background-color: red;
  border-radius: 50%;
  right: -15%;
  top: -20%;
  content: "";
  z-index: 3;
}

/*********************************************** batch page CSS*********************************************************************/

.batch_header_container {
  display: flex;
  justify-content: space-between;
  padding: 18px 22px;
  padding-right: 34px;
  align-items: center;
  background-color: var(--main-white);
}

.batch_header_container .batch_header_content h2 {
  color: #024c87;
  font-family: "DM Sans";
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: capitalize;
  display: flex;
  align-items: center;
}

.batch_header_container .batch_header_inp {
  position: relative;
  width: 25%;
}

.batch_header_container .batch_header_inp svg {
  position: absolute;
  top: 12%;
  left: 3%;
  height: 26px;
  width: 24px;
  color: #8c8c8c;
}

.batch_header_container .batch_header_inp input {
  border: 1px solid #d9d9d9;
  outline: none;
  width: 100%;
  padding: 6px 22px;
  padding-left: 35px;
  border-radius: 5px;
}

.batchlist_container {
  background-color: #e2e2e2;
  padding: 24px 32px;
}

.batchlist_container .batchlist_buttons {
  display: flex;
  gap: 10px;
  margin-bottom: 18px;
}

.batchlist_container .batchlist_buttons button {
  border-radius: 41px;
  border: 1px solid var(--primary-blue);
  padding: 4px 18px;
  color: var(--primary-blue);
  text-align: center;
  font-family: "DM Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: capitalize;
}

.batchlist_container .batchlist_buttons button.active {
  background-color: var(--primary-blue);
  color: var(--main-white);
}

.batchlist_container .batchlist_cards {
  height: 66vh;
  overflow-y: auto;
}

.scrollbar-template::-webkit-scrollbar {
  width: 7px;
}

/* Track */
.scrollbar-template::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 6px;
}

/* Handle */
.scrollbar-template::-webkit-scrollbar-thumb {
  background: var(--primary-blue);
  border-radius: 10px;
}

/* Handle on hover */
.scrollbar-template::-webkit-scrollbar-thumb:hover {
  background: var(--primary-blue);
}

.batchlist_cards .batchlist_card_items {
  border-radius: 5px;
  background: var(--main-white);
  margin-top: 14px;
  padding: 12px 18px 12px 12px;
  display: flex;
  justify-content: space-between;
  width: 99%;
}

.batchlist_card_items .batchlist_card_items_left {
  display: flex;
  gap: 10px;
}

.batchlist_card_items .batchlist_card_items_left img {
  width: 200px;
  border-radius: 8px;
  object-fit: cover;
}

.batchlist_card_items_left .batchlist_card_items_details h3 {
  color: var(--primary-blue);
  font-family: "DM Sans";
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: capitalize;
}

.batchlist_card_items_left .batchlist_card_items_details h4 {
  color: var(--light-gray);
  font-family: "DM Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: capitalize;
}

.batchlist_card_items_left .batchlist_card_items_details h5 {
  color: var(--light-gray);
  font-family: "DM Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: capitalize;
}

.batchlist_card_items .batchlist_card_items_right {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: end;
}

.batchlist_card_items_right .batchlist_card_item_options svg {
  fill: var(--primary-blue);
  font-size: 24px;
  cursor: pointer;
}
.batchlist_card_items_right .batchlist_card_item_options {
  position: relative;
}
.batchlist_card_item_options .batchlist_card_option_div {
  position: absolute;
  border-radius: 8px;
  border: 1px solid #fff;
  background: #fff;
  box-shadow: 0px 0px 6px 0px rgba(169, 169, 169, 0.25);
  padding: 10px;
  right: 1.5rem;
  top: 66%;
  width: 185px;
  cursor: pointer;
}
.batchlist_card_option_div .batchlist_card_info svg {
  font-size: 10px;
  height: 20px;
}

.batchlist_card_option_div .batchlist_card_info span {
  color: var(--primary-blue);
  font-family: "DM Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 21px */
  text-transform: capitalize;
}

.batchlist_card_items_right .batchlist_card_item_view a {
  display: flex;
  align-items: center;
  gap: 4px;
}
.batchlist_card_items_right .batchlist_card_item_view a span {
  color: var(--primary-blue);
  text-align: center;
  font-family: "DM Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px; /* 137.5% */
  text-decoration-line: underline;
  text-transform: capitalize;
}

.batchlist_card_items_right .batchlist_card_item_view a svg {
  fill: var(--primary-blue);
  font-size: 14px;
}

.bootstrap-modal-custom .modal-close-btn {
  position: absolute;
  right: 2%;
  top: 2%;
  fill: #a80000;
  font-size: 26px;
  cursor: pointer;
}

.batch-details-modal-body .batch-details-modal-name {
  display: flex;
  align-items: center;
  gap: 15px;
  padding-bottom: 12px;
  margin-bottom: 20px;
  border-bottom: 2px solid var(--light-gray);
}

.batch-details-modal-body .batch-details-modal-name img {
  width: 52px;
  height: 48px;
  border-radius: 6px;
  object-fit: cover;
}
.batch-details-modal-body .batch-details-modal-name span {
  color: var(--primary-blue);
  font-family: "DM Sans";
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: capitalize;
}

.batch-details-modal-body .batch-details-modal-info {
  display: flex;
  align-items: center;
  gap: 20px;
  padding-bottom: 12px;
  margin-bottom: 20px;
  border-bottom: 2px solid var(--light-gray);
}

.batch-details-modal-info .batch-modal-info-title span {
  color: var(--light-gray);
  display: block;
  font-family: "DM Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: capitalize;
  margin-bottom: 20px;
}

.batch-details-modal-body .batch-details-modal-meetlink label {
  color: var(--primary-blue);
  width: 100%;
  margin-bottom: 10px;
  font-family: "DM Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: capitalize;
}

.batch-details-modal-body .batch-details-modal-meetlink input,
.batch-details-modal-body .batch-details-modal-meetlink select {
  border: 2px solid var(--primary-white);
  padding: 8px 12px;
  border-radius: 5px;
  width: 100%;
  outline: none;
  color: var(--light-gray);
}

.batch-details-modal-meetlink .batch-modal-submit button {
  border-radius: 22px;
  background: var(--primary-blue);
  border: 1px solid var(--primary-blue);
  padding: 8px 12px;
  color: var(--main-white);
  text-align: center;
  font-family: "DM Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: capitalize;
  margin-top: 12px;
}

.batch-details-modal-meetlink .btn-success {
  background: var(--primary-blue);
  border: 1px solid var(--primary-blue);
}

.batch-details-modal-meetlink .btn-success:active {
  background: var(--primary-blue);
}

.batch-details-modal-body
  .batch-details-modal-meetlink
  .batch-modal-append-input {
  width: 87%;
  padding: 0 8px;
}
.batch-details-modal-meetlink .batch-modal-append-input:focus {
  box-shadow: none;
}

/********************************************************* Module Page CSS *******************************************************/

.module_header_container .module_header_title {
  background-color: var(--main-white);
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 10px 22px;
  border-bottom: 1px solid #e2e2e2;
}
.module_header_container .module_header_title svg {
  fill: var(--primary-blue);
  font-size: 22px;
}

.module_header_container .module_header_title img {
  height: 45px;
  width: 45px;
  object-fit: cover;
  border-radius: 50%;
}

.module_header_container .module_header_title h2 {
  color: #024c87;
  font-family: "DM Sans";
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin: 0;
  text-transform: capitalize;
}

.module_header_container .module_header_navs {
  background-color: var(--main-white);
  padding: 18px 62px;
  display: flex;
  align-items: center;
  gap: 40px;
  padding-bottom: 0;
}
.module_header_container .module_header_navs a {
  color: var(--light-gray);
  text-align: center;
  font-family: "DM Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: capitalize;
  padding-bottom: 10px;
}
.module_header_container .module_header_navs .active {
  color: var(--primary-violet);
  border-bottom: 2px solid var(--primary-violet);
  font-weight: 700;
}

.module_card_container {
  padding: 20px 54px;
}

.module_card_container .module_card_item {
  border-radius: 8px;
  background: var(--main-white);
  padding: 20px;
  margin-bottom: 12px;
}
.module_card_container .row {
  height: 67vh;
  overflow-y: auto;
}
.module_card_container .module_card_item img {
  width: 100%;
  border-radius: 8px;
  height: 169px;
}

.module_card_container .module_card_item svg {
  font-size: 28px;
  fill: #e30e00;
  cursor: pointer;
}

.module_card_container .module_card_item h4 {
  color: var(--primary-blue);
  font-family: "DM Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: capitalize;
  margin-top: 14px;
  margin-bottom: 6px;
}
.module_card_container .module_card_item h5 {
  color: var(--primary-white);
  font-family: "DM Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: capitalize;
  margin-bottom: 6px;
}
.module_card_container .module_card_item h6 {
  color: var(--light-gray);
  font-family: "DM Sans";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: capitalize;
  margin-bottom: 6px;
}
.module_card_container .module_card_item button {
  width: 100%;
  /* margin-top: 14px; */
  border-radius: 7px;
  border: 1px solid #c9e2ff;
  padding: 8px 22px;
  color: #0057ff;
  text-align: center;
  font-family: "DM Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: capitalize;
  background-color: var(--main-white);
}

.module_card_container .module_card_item button:hover {
  border: 1px solid #0057ff;
  background-color: #0057ff;
  color: var(--main-white);
}

.module_card_container .module_card_create button {
  background-color: var(--primary-violet);
  border: 1px solid var(--primary-violet);
  color: var(--main-white);
  text-align: center;
  font-family: "DM Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: capitalize;
  fill: var(--main-white);
  display: flex;
  align-items: center;
  justify-content: center;
}

.module_card_container .module_card_create button svg {
  fill: inherit;
  font-size: 22px;
}

.module_card_container .module_card_create button:hover {
  border: 1px solid var(--primary-violet);
  color: var(--primary-violet);
  background-color: var(--main-white);
  fill: var(--primary-violet);
}

.create-module-modal .create-module-modal-body {
  padding: 20px;
}

.create-module-modal .create-module-modal-body h2 {
  color: var(--primary-blue);
  text-align: center;
  font-family: "DM Sans";
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: capitalize;
}

.create-module-modal .create-module-modal-body label {
  color: var(--primary-blue);
  font-family: "DM Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  width: 100%;
  margin-bottom: 8px;
  margin-top: 32px;
}

.create-module-modal .create-module-modal-body input {
  border-radius: 5px;
  border: 1px solid var(--primary-white);
  width: 100%;
  padding: 12px;
  margin-bottom: 12px;
  outline: none;
}

.create-module-modal .create-module-modal-body button {
  background: #6aac29;
  border-radius: 52px;
  border: 1px solid;
  padding: 10px 29px;
  color: var(--main-white);
  margin-top: 42px;
  font-family: "DM Sans";
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: capitalize;
}

/*****************************  module stutent page css ************************************************************************/
.module_student_container {
  padding: 12px 55px;
}

.module_student_container .module_student_table {
  height: 62vh;
  overflow-y: auto;
}
.module_student_container .module_student_table table {
  width: 99%;
  overflow-x: auto;
}
.module_student_container .module_student_navs a {
  border-radius: 41px;
  border: 1px solid var(--primary-blue);
  color: var(--primary-blue);
  text-align: center;
  font-family: "DM Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: capitalize;
  padding: 6px 22px;
}

.module_student_container .module_student_table td button {
  border-radius: 6px;
  background: var(--primary-blue);
  padding: 2px 10px;
  border: 1px solid var(--primary-blue);
  color: var(--main-white);
  text-align: center;
  font-family: "DM Sans";
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: capitalize;
}
.module_student_container .module_student_navs .active {
  background-color: var(--primary-blue);
  color: var(--main-white);
}

.module_student_container .module_student_navs {
  display: flex;
  align-items: center;
  gap: 20px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.table-template-style {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0 10px;
}
.table-template-style thead tr th {
  color: var(--primary-blue);
  font-family: "DM Sans";
  padding: 10px;
  box-shadow: 0px 0px 4px 0px rgba(217, 217, 217, 0.08);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: capitalize;
}
.table-template-style tbody tr td {
  background-color: var(--main-white);
  padding: 10px;
  font-family: "DM Sans";
  color: var(--light-gray);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.table-template-style tbody tr {
  margin-bottom: 10px;
}

.table-template-style tbody tr td img {
  height: 36px;
  width: 36px;
  border-radius: 50%;
  object-fit: cover;
}

.table-template-style tbody tr td:first-child {
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
}
.table-template-style tbody tr td:last-child {
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
}

/*********************************************************** module schedule page css ************************************************/
.module-schedule-container {
  padding: 22px 40px;
}

.module-schedule-container .module-schedule-list {
  height: 68vh;
  overflow-y: auto;
  padding: 0px 15px;
  width: 98%;
}

.module-schedule-container .module-schedule-list .module-schedule-item {
  border-radius: 5px;
  background: #fff;
  margin-bottom: 10px;
  display: flex;
  align-items: start;
  justify-content: space-between;
  padding: 14px 22px;
}

.module-schedule-list .module-schedule-item h6 {
  color: #7d8185;
  font-family: "DM Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: capitalize;
  margin: 0;
}
.module-schedule-item .module-schedule-item-info {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
.module-schedule-item .module-schedule-item-info span {
  background-color: #11a63b;
  padding: 4px 8px;
  border-radius: 4px;
  color: #fff;
  font-size: 14px;
  display: inline-block;
  font-weight: 700;
}

.module-schedule-item .module-schedule-item-heading {
  width: 100%;
}

.module-schedule-item .module-schedule-item-heading h3 {
  color: var(--primary-black);
  font-size: 24px;
}

.module-schedule-item
  .module-schedule-item-heading
  .module-schedule-item-heading_content {
  font-size: 18px;
  color: #7d8185;
}

.module-schedule-item
  .module-schedule-item-heading
  .module-schedule-item-heading_content
  p {
  margin-bottom: 0;
}

.module-schedule-item .module-schedule-item-options {
  position: relative;
  width: 5%;
  display: flex;
  justify-content: end;
}

.module-schedule-item .module-schedule-item-options svg {
  fill: var(--primary-blue);
  font-size: 26px;
  cursor: pointer;
}
.module-schedule-item
  .module-schedule-item-options
  .module-schedule-options-div {
  position: absolute;
  right: 28%;
  top: 64%;
  border-radius: 8px;
  border: 1px solid #fff;
  background: #fff;
  box-shadow: 0px 4px 47.4px 0px rgba(169, 169, 169, 0.25);
  padding: 8px 14px;
  width: 120px;
}

.module-schedule-options-div .module-schedule-options-item:first-child {
  color: var(--primary-blue);
  font-family: "DM Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 21px */
  text-transform: capitalize;
  fill: var(--primary-blue);
}
.module-schedule-options-div .module-schedule-options-item:first-child svg {
  font-size: 16px;
}
.module-schedule-options-div .module-schedule-options-item:last-child {
  color: #e30e00;
  font-family: "DM Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 21px */
  text-transform: capitalize;
}
.module-schedule-options-div .module-schedule-options-item:last-child svg {
  fill: #e30e00;
  font-size: 16px;
}
.module-schedule-options-div .module-schedule-options-item {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 6px 0px;
  cursor: pointer;
}

.module-schedule-container .module-schedule-create {
  display: flex;
  justify-content: end;
  margin-top: 18px;
}

.module-schedule-container .module-schedule-create button {
  border-radius: 5px;
  background: var(--primary-blue);
  padding: 8px 22px;
  display: flex;
  align-items: center;
  border: 1px solid var(--primary-blue);
  color: #fff;
  text-align: center;
  font-family: "DM Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: capitalize;
}

.schedule-modal .schedule-modal-body h2 {
  color: var(--primary-blue);
  font-family: "DM Sans";
  text-align: center;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: capitalize;
}
.schedule-modal .schedule-modal-body .schedule-modal-form {
  margin-top: 42px;
}

.schedule-modal-body .schedule-modal-form .schedule-modal-inp label {
  color: var(--primary-blue);
  font-family: "DM Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  width: 100%;
  margin-bottom: 8px;
}
.schedule-modal-body .schedule-modal-form .schedule-modal-inp {
  margin-bottom: 15px;
}

.schedule-modal-body .schedule-modal-form .schedule-modal-inp input,
.schedule-modal-body .schedule-modal-form .schedule-modal-inp select {
  border-radius: 5px;
  background: var(--main-white);
  padding: 10px 14px;
  border: 2px solid #cfcccc;
  width: 100%;
  outline: none;
}

.schedule-modal-form .schedule-modal-submit {
  margin-top: 30px;
  display: flex;
  justify-content: center;
  margin-bottom: 30px;
}
.schedule-modal-form .schedule-modal-submit button {
  border-radius: 55px;
  background: #6aac29;
  padding: 8px 22px;
  border: 1px solid;
  color: var(--main-white);
  font-family: "DM Sans";
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: capitalize;
}

.module_view_header_container .view_header_title {
  background-color: var(--main-white);
  padding: 22px;
  display: flex;
  align-items: center;
  gap: 8px;
}
.module_view_header_container .view_header_title h2 {
  color: var(--primary-violet);
  font-family: "DM Sans";
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: capitalize;
  margin: 0;
}
.module_view_header_container .view_header_title svg {
  fill: var(--primary-violet);
  font-size: 18px;
}

.module_viewcards_container .module_viewcards_item {
  display: flex;
  align-items: center;
  gap: 24px;
  background-color: var(--main-white);
  padding: 22px;
  border-radius: 5px;
}

.module_viewcards_container .module_viewcards_item img {
  height: 152px;
}

.module_viewcards_item_details .module_viewcards_item_title h3 {
  color: var(--primary-blue);
  font-family: "DM Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: capitalize;
  margin: 0;
}

.module_viewcards_item_details .module_viewcards_item_title h4 {
  color: var(--primary-white);
  font-family: "DM Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: capitalize;
  margin: 0;
}

.module_viewcards_item_details .module_viewcards_item_title h5 {
  color: var(--light-gray);
  font-family: "DM Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: capitalize;
  margin: 0;
}

.module_viewcards_container .module_viewcards_item_details button {
  border-radius: 5px;
  background: var(--primary-blue);
  border: 1px solid var(--primary-blue);
  width: 100%;
  padding: 8px 22px;
  width: 100%;
  color: var(--main-white);
  font-family: "DM Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: capitalize;
}

.module_viewcards_container .module_viewcards_item_details {
  width: 90%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 140px;
}

.module_viewcards_container {
  padding: 14px 55px;
  margin-top: 18px;
}

.module_video_header_container .video_header_editmodule {
  display: flex;
  align-items: center;
  gap: 18px;
}

.module_video_header_container {
  background-color: var(--main-white);
  padding: 14px 22px;
  border-top: 1px solid #e2e2e2;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.module_video_header_container .video_header_editmodule h3 {
  color: var(--primary-blue);
  font-family: "DM Sans";
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: capitalize;
}

.module_video_header_container .video_header_editmodule button {
  background-color: transparent;
  border: none;
  border-radius: 50%;
}

.module_video_header_container .video_header_addvideo button {
  border-radius: 34px;
  border: 2px dashed var(--primary-blue);
  padding: 8px 22px;
  background-color: transparent;
  color: var(--primary-blue);
  font-family: "DM Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: capitalize;
  margin-right: 22px;
}

.module_video_header_container .video_header_addvideo button:hover {
  background-color: var(--primary-blue);
  color: var(--main-white);
}

.video_wrapper_container {
  position: relative;
  border-radius: 8px;
  display: inline-block;
}
.video_wrapper_container img,
.video_wrapper_container video {
  border-radius: 8px;
  position: relative;
}

.video_wrapper_container svg {
  position: absolute;
  fill: var(--primary-blue);
  cursor: pointer;
}

.module_videolist_div {
  height: 64vh;
  overflow-y: auto;
  padding: 20px;
  width: 98%;
  margin-top: 14px;
}

.module_video_cards_container {
  display: flex;
  gap: 12px;
  /* padding: 20px; */
  margin-top: 14px;
}

.module_video_cards_container .module_video_cards_title {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.module_video_cards_container .module_video_cards_title h2 {
  color: var(--primary-blue);
  font-family: "DM Sans";
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%; /* 30px */
  text-transform: capitalize;
  margin: 0;
}
.module_video_cards_container .module_video_cards_title svg {
  fill: var(--primary-blue);
  font-size: 28px;
  cursor: pointer;
}
.module_video_cards_container .module_video_cards_description {
  padding: 0px 10px;
  width: 100%;
}
.module_video_cards_container .module_video_cards_description h3 {
  color: var(--light-gray);
  font-family: "DM Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 24px */
}

.module_video_cards_container .module_video_cards_description button {
  padding: 8px 22px;
  margin-top: 30px;
  background-color: transparent;
  border-radius: 7px;
  border: 1px solid var(--primary-blue);
  color: var(--primary-blue);
  text-align: center;
  font-family: "DM Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: capitalize;
}

.module_video_cards_container .module_video_cards_description button:hover {
  background-color: var(--primary-blue);
  color: var(--main-white);
}

.module_video_cards_container .module_video_cards_options {
  position: relative;
}

.module_video_cards_options .module_video_cards_options-div {
  position: absolute;
  right: 42%;
  background-color: var(--main-white);
  padding: 0px 9px;
  width: 106px;
  border-radius: 6px;
}

.indexedStep {
  color: var(--main-white);
  width: 30px;
  height: 30px;
  font-size: 12px;
  background-color: #c9d5fd;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.upload-video-modal-body h2 {
  color: var(--primary-blue);
  text-align: center;
  font-family: "DM Sans";
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: capitalize;
  margin-bottom: 14px;
}

.upload-video-modal-body .indexedStep.accomplished {
  background: var(--primary-blue);
  color: white;
  border-style: none;
}
.upload-video-modal-body .RSPBprogressBar {
  background-color: #c9d5fd !important;
}
.upload-video-modal-body .RSPBprogressBar .RSPBprogression {
  background: var(--primary-blue) !important;
}

.upload-video-modal .upload-video-modal-body {
  padding: 20px;
  margin-top: 15px;
}

.upload-video-modal-body .upload-progress-bar .upload-progress-bar-div {
  width: 350px;
}

.upload-video-modal-body .upload-progress-bar {
  display: flex;
  justify-content: center;
  margin-bottom: 60px;
}

.upload-video-modal-form .upload-video-modal-inp label {
  color: var(--primary-blue);
  font-family: "DM Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  width: 100%;
  margin-bottom: 6px;
}

.upload-video-modal-form .upload-video-modal-inp .ql-editor {
  height: 150px;
}
.upload-video-modal-form .upload-video-modal-inp {
  margin-bottom: 20px;
}

.upload-video-modal-form .upload-video-modal-inp input,
.upload-video-modal-form .upload-video-modal-inp textarea {
  border-radius: 5px;
  border: 1px solid var(--primary-white);
  outline: none;
  padding: 8px 14px;
  width: 100%;
}

.upload-video-modal-form .upload-video-modal-submit {
  display: flex;
  justify-content: center;
  margin-top: 60px;
  gap: 14px;
}

.upload-video-modal-form .upload-video-modal-submit button {
  padding: 8px 28px;
  border-radius: 55px;
  background: #6aac29;
  border: 1px solid #6aac29;
  color: var(--main-white);
  font-family: "DM Sans";
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  width: 128px;
  text-transform: capitalize;
  text-align: center;
}

.upload-video-modal-form .upload-video-thumbnail-label {
  border-radius: 34px;
  border: 1px solid var(--primary-blue);
  padding: 8px 22px;
  display: inline-block;
  width: auto !important;
  cursor: pointer;
}

.upload-video-modal-form .upload-video-modal-inp h6 {
  color: var(--primary-blue);
  text-align: center;
  font-family: "DM Sans";
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%; /* 30px */
}

.upload-video-modal-form .upload-video-modal-selectfile {
  display: flex;
  justify-content: center;
}

.upload-video-preview .upload-video-preview-title {
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content: space-between;
  margin-top: 14px;
}

.upload-video-preview .upload-video-preview-title h3 {
  color: var(--primary-blue);
  font-family: "DM Sans";
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%; /* 30px */
  text-transform: capitalize;
}

.upload-video-preview
  .upload-video-preview-desc
  .upload-video-preview-desc-div {
  color: var(--primary-black);
  font-family: "DM Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 24px */
  margin-bottom: 15px;
}

.upload-video-preview .upload-video-preview-desc {
  height: 26vh;
  overflow-y: auto;
  width: 98%;
}

.upload-video-preview .upload-video-preview-file {
  margin-top: 20px;
  display: flex;
  align-items: center;
  gap: 20px;
}

.upload-video-preview .upload-video-preview-file img,
.upload-video-preview .upload-video-preview-file video {
  border-radius: 8px;
  width: 248px;
  height: 161px;
}
.upload-video-modal-selectfile .upload-video-selectfile-div {
  border: 1px solid var(--primary-blue);
  padding: 8px;
  border-radius: 8px;
  margin: 16px 0px;
}

.upload-video-modal-selectfile .upload-video-selectfile-div p {
  color: var(--primary-violet);
  font-family: "DM Sans";
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 0;
  display: flex;
  align-items: center;
  gap: 10px;
}

.upload-video-modal-selectfile .upload-video-selectfile-div text {
  border-radius: 6px;
  background: #b4cadb;
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 8px;
  margin-top: 8px;
}
.upload-video-modal-selectfile .upload-video-selectfile-div svg {
  font-size: 22px;
  fill: var(--primary-blue);
  cursor: pointer;
}

.upload-video-modal-form .upload-video-modal-or,
.work_form_container .upload-video-modal-or {
  margin: 20px 0px;
  padding: 4px 22px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12px;
}

.upload-video-modal-form .upload-video-modal-or span,
.work_form_container .upload-video-modal-or span {
  height: 2px;
  background-color: #b3b3b3;
  display: inline-block;
  width: 35%;
}

.upload-video-modal-form .upload-video-modal-or b,
.work_form_container .upload-video-modal-or b {
  color: #616161;
  font-family: "DM Sans";
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: uppercase;
}

.upload-video-modal-body .dropzone,
.work_form_container .dropzone {
  border: 2px dashed var(--light-gray);
  border-radius: 4px;
  text-align: center;
  margin: 12px 0px;
  padding: 30px;
  cursor: pointer;
}

.upload-video-modal-body .dropzone-select,
.work_form_container .dropzone-select {
  cursor: default;
  padding: 20px;
}

.upload-video-modal-body .dropzone button,
.work_form_container .dropzone button,
.work_form_container .dropzone span {
  color: var(--main-white);
  font-family: "DM Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: capitalize;
  border-radius: 37px;
  background: #6794b7;
  padding: 8px 22px;
  border: 1px solid #6794b7;
  margin-bottom: 8px;
}
.work_form_container .dropzone span {
  display: inline-block;
}

.upload-video-modal-body .dropzone.active,
.work_form_container .dropzone.active {
  border-color: #0088cc;
}

.upload-video-modal-body .dropzone p,
.work_form_container .dropzone p {
  margin: 0;
  color: #999;
  text-align: center;
  font-family: "DM Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: capitalize;
}

.upload-video-modal-body .upload-video-modal-file-select,
.work_form_container .upload-video-modal-file-select {
  border-radius: 8px;
  padding: 10px;
  border: 2px solid var(--primary-blue);
  padding: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.work_form_container .work_form_item {
  background: #fff;
  border: 1px solid #d9d9d9;
  border-radius: 5px;
  padding: 16px 20px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}

.work_form_container .work_form_item .work_form_item_file {
  font-size: 26px;
  fill: #024c87;
}
.work_form_container .work_form_item .work_form_item_close {
  font-size: 26px;
  fill: #6e6d7a;
  cursor: pointer;
}
.work_form_container .work_form_item span {
  color: #6e6d7a;
  font-family: DM Sans;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-align: justify;
}

.upload-video-modal-file-select .upload-video-modal-file-txt svg {
  font-size: 28px;
  fill: var(--primary-blue);
}

.upload-video-modal-file-select .upload-video-modal-file-txt {
  display: flex;
  align-items: center;
  gap: 10px;
}
.upload-video-modal-file-select .upload-video-modal-file-txt span {
  color: #6794b7;
  font-family: "DM Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 30px */
}

.upload-video-modal-file-select .upload-video-modal-file-close svg {
  font-size: 22px;
  fill: var(--primary-blue);
  cursor: pointer;
}

.video_view_container .video_view_left {
  padding: 18px;
  height: 75vh;
  overflow-y: auto;
  margin-top: 18px;
  padding-top: 0;
}

.video_view_container .video_view_left .video_wrapper_container {
  width: 100%;
}
.video_view_container .video_view_right {
  padding: 30px;
  padding-left: 0px;
  padding-top: 0px;
  height: 75vh;
  width: 93%;
  margin-top: 18px;
  overflow-y: auto;
}

.video_view_container .video_view_left h2 {
  color: #46484e;
  font-family: "DM Sans";
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%; /* 48px */
  text-transform: capitalize;
  margin-top: 8px;
  margin-bottom: 0;
}

.video_view_container .video_view_left .video_view_left_div {
  color: #7d8185;
  font-family: "DM Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 24px */
}

.video_view_right .video_view_right_item {
  padding: 10px;
  margin-bottom: 10px;
  display: flex;
  gap: 10px;
}
.video_view_right .video_view_right_item.active {
  border-radius: 5px;
  background: #fff;
  box-shadow: 0px 4px 6px 0px rgba(175, 175, 175, 0.35);
}
.video_view_right .video_view_right_item .video_view_right_item_desc h2 {
  color: #46484e;
  font-family: "DM Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%; /* 24px */
  text-transform: capitalize;
}

.video_view_right .video_view_right_item .video_view_right_item_desc h3 {
  color: #b0b0b0;
  font-family: "DM Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: capitalize;
}

.resource_list_container {
  padding: 24px;
}

.resource_list_container .row {
  height: 63vh;
  overflow-y: auto;
  margin: 0px 4px;
}

.resource_list_container .resource_list_item {
  border-radius: 5px;
  background: var(--main-white);
  padding: 14px 18px;
  margin-bottom: 15px;
}

.resource_list_container .resource_list_item h4 {
  color: var(--primary-blue);
  font-family: "DM Sans";
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%; /* 30px */
}
.resource_list_container .resource_list_item .resource_list_btns {
  display: flex;
  justify-content: space-between;
  margin-top: 35px;
  align-items: center;
}

.resource_list_item .resource_list_btns button {
  border-radius: 34px;
  border: 1px solid var(--primary-blue);
  padding: 8px 22px;
  color: var(--primary-blue);
  text-align: center;
  font-family: "DM Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: capitalize;
  background-color: transparent;
}

.resource_list_item .resource_list_btns svg {
  fill: #e30e00;
  cursor: pointer;
  font-size: 22px;
}
.assignment_header_container {
  background: var(--main-white);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 22px;
}

.assignment_header_container .assignment_header_heading {
  display: flex;
  align-items: center;
  gap: 14px;
}

.assignment_header_container .assignment_header_heading h2 {
  color: var(--primary-violet);
  text-align: center;
  font-family: "DM Sans";
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: capitalize;
  margin: 0;
}
.assignment_header_container .assignment_header_heading svg {
  fill: var(--primary-violet);
  font-size: 16px;
}

.assignment_header_container .assignment_header_btn button {
  border-radius: 34px;
  border: 1px solid var(--primary-violet);
  background: var(--primary-violet);
  padding: 12px 18px;
  color: var(--main-white);
  text-align: center;
  font-family: "DM Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: capitalize;
  margin-right: 8px;
}
.assignment_header_container .assignment_header_btn button svg {
  font-size: 18px;
}

.allassignment_table_container {
  padding: 10px 28px;
}

.allassignment_table_container .allassignment_table_tag td,
.allassignment_table_container .allassignment_table_tag th {
  text-align: center;
}

.allassignment_table_container
  .allassignment_table_tag
  td
  .assignment_table_btns {
  display: flex;
  align-items: center;
  gap: 32px;
}

.allassignment_table_container .allassignment_table_tag td button {
  border-radius: 6px;
  background: var(--primary-blue);
  padding: 2px 10px;
  border: 1px solid var(--primary-blue);
  color: var(--main-white);
  text-align: center;
  font-family: "DM Sans";
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: capitalize;
}

.allassignment_table_container .allassignment_table_tag td svg {
  fill: var(--primary-blue);
  font-size: 22px;
  cursor: pointer;
}

.allassignment_table_container .assignment_table_edit {
  position: relative;
}

.allassignment_table_container .assignment_table_options {
  border-radius: 5px;
  background: var(--main-white);
  box-shadow: 0px 0px 6px 0px rgba(175, 175, 175, 0.45);
  padding: 0px 15px;
  position: absolute;
  z-index: 1;
  right: 65%;
}

.allassignment_table_container .allassignment_table_filter {
  display: flex;
  justify-content: end;
  align-items: center;
  margin-top: 30px;
}

.assignment_table_div {
  height: 67vh;
  overflow-y: auto;
  padding: 0px 10px;
  margin-top: 10px;
}
.assignment_view_table_div {
  height: 71vh;
  overflow-y: auto;
  padding: 0px 10px;
  margin-top: 16px;
}
.allassignment_table_filter .allassignment_table_sort button {
  background-color: transparent;
  border: none;
  color: #7d8185;
  text-align: center;
  font-family: "DM Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: capitalize;
  display: flex;
  align-items: center;
  gap: 6px;
  margin-right: 22px;
}

.allassignment_table_filter .allassignment_table_sort {
  position: relative;
  width: 16%;
  display: flex;
  justify-content: center;
}

.allassignment_table_sort .allassignment_table_sort_list {
  position: absolute;
  border-radius: 5px;
  background: var(--main-white);
  box-shadow: 0px 0px 6px 0px rgba(175, 175, 175, 0.45);
  padding: 8px 12px;
  left: -67px;
  width: 200px;
  z-index: 2;
  top: 30px;
}

.allassignment_table_sort_list .allassignment_table_sort_list_item h4 {
  color: var(--primary-blue);
  font-family: "DM Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 144.5%; /* 23.12px */
  text-transform: capitalize;
  margin: 0;
}

.allassignment_table_sort_list_item .allassignment_table_sort_heading {
  display: flex;
  align-items: center;
  gap: 6px;
  justify-content: space-between;
}
.allassignment_table_sort_list_item .allassignment_table_sort_heading span {
  color: var(--primary-blue);
  font-size: 12px;
  text-decoration: underline;
  cursor: pointer;
  font-weight: 600;
}
.allassignment_table_sort_list .allassignment_table_sort_list_item {
  margin-bottom: 10px;
  padding-bottom: 8px;
  border-bottom: 2px solid #eee;
}

.allassignment_table_sort_list .allassignment_table_sort_list_item:last-child {
  border: none;
}

.allassignment_table_sort_list_item .allassignment_table_list_item_inp {
  display: flex;
  align-items: center;
  gap: 6px;
}

.allassignment_table_sort_list_item .allassignment_table_list_item_inp label {
  color: var(--primary-blue);
  text-align: center;
  font-family: "DM Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 144.5%; /* 23.12px */
  text-transform: capitalize;
}

.allassignment_table_sort_list_item .allassignment_table_list_item_inp select {
  width: 100%;
  outline: none;
  padding: 4px 12px;
  border-radius: 5px;
  border: 1px solid #d9d9d9;
}

.allassignment_table_container
  .allassignment_table_tag
  td
  .assignmentview_table_btns {
  display: flex;
  justify-content: center;
  gap: 16px;
}

.submission-modal-body .submission-modal-body-heading h2 {
  color: var(--primary-blue);
  text-align: center;
  font-family: "DM Sans";
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: capitalize;
  margin-bottom: 14px;
}

.submission-modal-body .submission-modal-body-inp label {
  color: var(--primary-blue);
  font-family: "DM Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 8px;
  width: 100%;
}

.submission-modal-body .submission-modal-body-inp .submission-modal-body-item {
  background-color: var(--primary-blue);
  border-radius: 6px;
  font-size: 16px;
  padding: 4px 8px;
  margin-bottom: 10px;
}

.submission-modal-body-inp .submission-modal-body-item h4 {
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  font-size: 16px;
  margin: 0;
  color: var(--main-white);
}

.submission-modal-body-inp .submission-modal-body-item a {
  color: var(--main-white);
  text-decoration: underline;
  font-size: 14px;
}
.submission-modal-body-inp .submission-modal-body-link {
  display: flex;
  justify-content: end;
}

.submission-modal-body-inp .submission-modal-body-rating {
  border-radius: 5px;
  background: var(--main-white);
  box-shadow: 0px 0px 4px 0px rgba(175, 175, 175, 0.75);
  padding: 8px;
}
.submission-modal-body-inp .submission-modal-body-rating ul {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.submission-modal-body-inp .submission-modal-body-rating ul li {
  padding: 4px 12px;
  color: var(--primary-blue);
  cursor: pointer;
  border-radius: 6px;
}

.submission-modal-body-inp .submission-modal-body-rating ul li.active {
  background-color: var(--primary-blue);
  color: var(--main-white);
}

.submission-modal-body .submission-modal-body-inp {
  margin-bottom: 14px;
}

.submission-modal-body .submission-modal-body-inp textarea {
  border-radius: 5px;
  border: 1px solid var(--primary-white);
  padding: 8px 14px;
  outline: none;
  width: 100%;
}

.submission-modal-body .submission-modal-body-submit {
  display: flex;
  justify-content: end;
  margin-top: 14px;
}
.submission-modal-body .submission-modal-body-submit button {
  border-radius: 5px;
  background: var(--primary-blue);
  color: var(--main-white);
  font-family: "DM Sans";
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: capitalize;
  padding: 8px 14px;
  border: 1px solid var(--primary-blue);
}

.submission-modal-body .submission-modal-body-links {
  border-radius: 5px;
  background: var(--main-white);
  box-shadow: 0px 0px 4px 0px rgba(175, 175, 175, 0.75);
  margin-bottom: 14px;
  padding: 8px 14px;
  display: flex;
  align-items: center;
  gap: 14px;
}

.submission-modal-body .submission-modal-body-links svg {
  fill: var(--primary-blue);
  font-size: 22px;
}
.submission-modal-body .submission-modal-body-links a {
  font-size: 16px;
  color: var(--primary-blue);
  display: flex;
  align-items: center;
  gap: 4px;
}

.submission-modal-body .submission-modal-body-links a:hover {
  text-decoration: underline;
}
.submission-modal-body .submission-modal-body-links a svg {
  font-size: 14px;
}

.comment-modal-body .comment-modal-body-heading {
  color: var(--primary-blue);
  text-align: center;
  font-family: "DM Sans";
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: capitalize;
  margin-bottom: 14px;
}

.comment-modal-body .comment-modal-body-inp label {
  color: var(--primary-blue);
  font-family: "DM Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 8px;
}

.comment-modal-body .comment-modal-body-inp textarea {
  border-radius: 5px;
  border: 1px solid var(--primary-white);
  padding: 8px 14px;
  outline: none;
  width: 100%;
}
.comment-modal-body .comment-modal-body-submit {
  display: flex;
  justify-content: center;
  margin-top: 16px;
}

.comment-modal-body .comment-modal-body-submit button {
  border-radius: 5px;
  background: var(--primary-blue);
  color: var(--main-white);
  font-family: "DM Sans";
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: capitalize;
  padding: 8px 14px;
  border: 1px solid var(--primary-blue);
}
.work_form_container .work_form_div {
  padding: 12px 32px;
}

.work_form_container .work_form_div .work_form_div_row {
  height: 75vh;
  overflow-y: auto;
}

.work_form_container .work_form_div .work_form_inp input,
.work_form_container .work_form_div .work_form_inp select,
.work_form_container .work_form_div .work_form_inp textarea {
  border-radius: 5px;
  border: 1px solid var(--primary-white);
  background: var(--main-white);
  padding: 10px 12px;
  width: 100%;
  outline: none;
}

.work_form_container .work_form_div .work_form_inp label {
  color: var(--primary-blue);
  font-family: "DM Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.work_form_container .work_form_div .work_form_inp {
  margin-bottom: 18px;
}

/*********************************************** Profile Page CSS ************************************************/
.profile_form_container {
  border-radius: 5px;
  background: var(--main-white);
  padding: 22px;
  height: 87vh;
  overflow-y: auto;
  margin: 20px;
  margin-bottom: 0;
}

.profile_form_container .profile_form_heading h2 {
  color: var(--primary-blue);
  font-family: "DM Sans";
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 144.5%; /* 34.68px */
  text-transform: capitalize;
}

.profile_form_container .profile_form_div .profile_form_inp input {
  border-radius: 5px;
  border: 1px solid var(--primary-white);
  padding: 10px 14px;
  outline: none;
  width: 100%;
}

.profile_form_container .profile_form_div .profile_form_inp_disable input {
  background: #e2e2e2;
  cursor: not-allowed;
}

.profile_form_container .profile_form_div .profile_form_inp label {
  color: var(--primary-blue);
  font-family: "DM Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.profile_form_container .profile_form_div .profile_form_inp {
  margin-bottom: 18px;
}

.profile_form_container .profile_form_div .profile_form_submit {
  display: flex;
  justify-content: end;
  margin-top: 14px;
}

.profile_form_container .profile_form_div .profile_form_submit button {
  border-radius: 5px;
  background: var(--primary-violet);
  padding: 8px 22px;
  color: var(--main-white);
  font-family: "DM Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 144.5%; /* 23.12px */
  text-transform: capitalize;
  border: 1px solid var(--primary-violet);
}

.profile_form_div .profile_form_file {
  display: flex;
  justify-content: center;
  margin-bottom: 62px;
}

.profile_form_div .profile_form_file label {
  height: 199px;
  width: 199px;
  border-radius: 50%;
  position: relative;
  cursor: pointer;
}
.profile_form_div .profile_form_file label img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
}

.profile_form_div .profile_form_file label svg {
  position: absolute;
  right: 15px;
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
  bottom: 0;
  scale: 0.9;
  border-radius: 50%;
}

/************************************************************ QNA PAGE CSSS ********************************************/

.qna_accordian_container {
  padding: 10px 32px;
}

.qna_items .qna_header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.qna_items .program_preview_info {
  margin-top: 12px;
  display: flex;
  align-items: center;
  justify-content: end;
  gap: 12px;
}

.qna_items .program_preview_info .program_preview_info_name {
  border-radius: 27px;
  padding: 8px 12px;
  background: #e8fff5;
  color: #11a63b;
  font-family: "DM Sans";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  text-transform: capitalize;
}

.qna_items .program_preview_info .program_preview_info_date {
  color: #ababab;
  font-family: "DM Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
}
.qna_items .program_preview_info .program_preview_info_date b {
  color: #00b336;
  text-align: center;
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 700;
  line-height: 150%; /* 24px */
  margin-right: 4px;
}

.qna_items .accordion-button {
  display: block;
}

.qna_accordian_div .accordion-button::after {
  content: none;
}

.qna_accordian_div .accordion-button {
  border-radius: 10px;
}
.qna_accordian_div .qna_items {
  margin-top: 30px;
  border-radius: 5px;
  border: 1.6px solid var(--light-gray);
  background: var(--main-white);
}

.qna_accordian_div .qna_items_active {
  border: 1.6px solid var(--primary-violet);
}

.qna_accordian_div .qna_items h3 {
  color: var(--primary-black);
  font-family: "DM Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  margin: 0;
  line-height: 129.3%; /* 20.688px */
}

.qna_accordian_div .accordion-button:not(.collapsed) {
  box-shadow: none;
  background-color: transparent;
}
.qna_accordian_div .accordion-button:focus {
  box-shadow: none;
  border-color: transparent;
}

.qna_accordian_div .qna_body p {
  color: var(--primary-black);
  font-family: "DM Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.qna_accordian_div .qna_header .qna_header_heading {
  display: flex;
  align-items: center;
  gap: 14px;
  /* width: 100%; */
}

.qna_accordian_div .qna_header .qna_header_heading span {
  border-radius: 27px;
  padding: 8px 12px;
  background: #e8fff5;
  color: #11a63b;
  font-family: "DM Sans";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 18px */
  text-transform: capitalize;
}

.qna_accordian_div .qna_header .qna_header_date {
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content: end;
  width: 23%;
}

.qna_accordian_div .qna_header .qna_header_date span {
  color: #ababab;
  font-family: "DM Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 18px */
}

.qna_accordian_div .qna_body .qna_body_user {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 20px;
}

.qna_accordian_div .qna_body .qna_body_user img {
  height: 42px;
  width: 42px;
  object-fit: cover;
  border-radius: 50%;
}

.qna_accordian_div .qna_body .qna_body_user span {
  color: #9a9a9a;
  font-family: "DM Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 21px */
  text-transform: capitalize;
}

.qna_accordian_div .qna_body .qna_body_desc {
  display: flex;
  flex-direction: column;
  /* align-items: end; */
}

.qna_items .accordion-body {
  padding-top: 0px;
}

.qna_accordian_div .qna_body .qna_body_desc svg {
  color: #6aac29;
  font-size: 24px;
  cursor: pointer;
}

.qna_accordian_div .qna_body .qna_body_inp {
  display: flex;
  flex-direction: column;
  align-items: end;
}

.qna_accordian_div .qna_body .qna_body_inp textarea {
  border-radius: 5px;
  border: 1px solid var(--primary-white);
  padding: 10px 14px;
  width: 100%;
  outline: none;
}

.qna_accordian_div .qna_body .qna_body_inp button {
  margin-top: 10px;
  border-radius: 6px;
  background: #6aac29;
  padding: 8px 14px;
  color: var(--main-white);
  font-family: "DM Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%; /* 24px */
  text-transform: capitalize;
  border: 1px solid #6aac29;
}

.qna_accordian_container {
  margin-top: 20px;
}

.qna_accordian_container .qna_accordian_links {
  display: flex;
  align-items: center;
  gap: 24px;
}

.qna_accordian_container .qna_accordian_options {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 14px;
}

.qna_accordian_container .qna_accordian_options .qna_accordian_select select {
  width: 100%;
  padding: 10px 12px;
  border-radius: 5px;
  background: #fff;
  border: 1px solid var(--primary-white);
  outline: none;
}

.qna_accordian_container .qna_accordian_scroll {
  height: 75vh;
  overflow-y: auto;
  padding: 0px 10px;
  margin-top: 6px;
}

.activebatch_card_container {
  padding: 30px;
  padding-bottom: 0;
}
.qna_accordian_container .qna_accordian_links a {
  color: #7d8185;
  font-family: "DM Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: capitalize;
  padding-bottom: 6px;
  font-weight: 700;
}

.qna_accordian_container .qna_accordian_links a.active {
  color: var(--primary-violet);
  border-bottom: 2px solid var(--primary-violet);
}

/*********************************************** Home Page Css *****************************************************/

.activebatch_card_carousel .activebatch_card_item {
  background-color: var(--main-white);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  padding: 14px 22px;
  border-radius: 8px;
  margin: 20px 0px;
}

.activebatch_card_item .activebatch_card_item_title {
  display: flex;
  align-items: center;
  gap: 14px;
  margin-bottom: 18px;
}

.activebatch_card_item .activebatch_card_item_title span {
  color: rgba(0, 0, 0, 0.8);
  font-family: "DM Sans";
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.activebatch_card_item .activebatch_card_item_info {
  display: flex;
  align-items: center;
  gap: 12px;
}

.activebatch_card_item_info .activebatch_card_item_desc h4 {
  color: #bcbcbc;
  font-family: "DM Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 200.7%; /* 32.112px */
  margin: 0;
}

.activebatch_card_item .activebatch_card_item_btn {
  display: flex;
  justify-content: end;
  margin-top: 14px;
}

.activebatch_card_item .activebatch_card_item_btn button {
  border-radius: 5px;
  background: var(--primary-violet);
  border: 1px solid var(--primary-violet);
  padding: 8px 12px;
  display: flex;
  align-items: center;
  gap: 6px;
}

.activebatch_card_item .activebatch_card_item_btn button svg {
  fill: var(--main-white);
  font-size: 16px;
}

.activebatch_card_item .activebatch_card_item_btn button span {
  color: var(--main-white);
  font-family: "DM Sans";
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.activebatch_card_carousel .owl-nav .owl-prev button,
.activebatch_card_carousel .owl-nav .owl-next button {
  border: 1px solid var(--primary-violet);
  height: 35px;
  width: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--primary-violet);
  border-radius: 50%;
}

.activebatch_card_carousel .owl-nav .owl-prev:hover,
.activebatch_card_carousel .owl-nav .owl-next:hover {
  background-color: transparent;
}

.activebatch_card_carousel .owl-nav .owl-prev {
  position: absolute;
  top: 35%;
  left: -21px;
}

.activebatch_card_carousel .owl-nav .owl-next {
  position: absolute;
  top: 35%;
  right: -21px;
}

.home_header_container .home_header_btn button {
  border-radius: 8px;
}

.create-schedule-modal-body .ql-editor {
  height: 130px;
}

.create-schedule-modal-body .create-schedule-modal-title h2 {
  color: var(--primary-blue);
  font-family: "DM Sans";
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: capitalize;
  text-align: center;
  margin-bottom: 25px;
}

.create-schedule-modal-body .create-schedule-modal-inp label {
  color: var(--primary-blue);
  font-family: "DM Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  width: 100%;
  margin-bottom: 4px;
}
.create-schedule-modal-body .create-schedule-modal-inp input,
.create-schedule-modal-body .create-schedule-modal-inp textarea,
.create-schedule-modal-body .create-schedule-modal-inp select {
  width: 100%;
  padding: 10px 12px;
  border-radius: 5px;
  background: #fff;
  border: 1px solid var(--primary-white);
  outline: none;
}

.create-schedule-modal-body .create-schedule-modal-inp {
  margin-bottom: 14px;
}

.create-schedule-modal-body .create-schedule-modal-or {
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content: center;
  margin: 22px 0px;
}

.create-schedule-modal-body .create-schedule-modal-or span {
  background-color: var(--light-gray);
  height: 2px;
  width: 42%;
}
.create-schedule-modal-body .create-schedule-modal-or b {
  color: var(--main-black);
  font-family: "DM Sans";
  font-size: 20.118px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: capitalize;
}

.create-schedule-modal-body .create-schedule-modal-groupinp {
  display: flex;
  align-items: center;
  gap: 10px;
}

.create-schedule-modal-body .create-schedule-modal-groupinp input {
  padding: 10px 12px;
  border-radius: 5px;
  background: #fff;
  border: 1px solid var(--primary-white);
  outline: none;
  width: 65%;
}
.create-schedule-modal-body .create-schedule-modal-groupinp button {
  border-radius: 5px;
  background: #6aac29;
  color: #fff;
  font-family: "DM Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  padding: 10px 8px;
  width: 35%;
  border: 1px solid#6AAC29;
}

.create-schedule-modal-body .create-schedule-modal-submit {
  display: flex;
  justify-content: center;
  gap: 10px;
  align-items: center;
  margin-top: 40px;
}

.create-schedule-modal-body .create-schedule-modal-submit button {
  border-radius: 55px;
  background: #6aac29;
  color: var(--main-white);
  font-family: "DM Sans";
  font-size: 19.882px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: capitalize;
  padding: 6px 22px;
  border: 1px solid #6aac29;
}

/***************************************************** Login Page CSS ***************************************************/

.login_container {
  padding: 30px;
  height: 100vh;
}

.login_container .row {
  height: 100%;
}

.loginform_container {
  border-radius: 12px;
  background: #fff;
  padding: 16px 22px;
  height: 100%;
}

.loginform_container .loginform_title {
  margin: 40px 0px;
}

.loginform_container .loginform_title h2 {
  color: var(--primary-violet);
  font-family: "DM Sans";
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.loginform_container .loginform_inp {
  border-radius: 5px;
  border: 1px solid var(--primary-white);
  padding: 8px 12px;
  display: flex;
  align-items: center;
  gap: 12px;
  margin-bottom: 20px;
}

.loginform_container .loginform_inp svg {
  fill: var(--light-gray);
  font-size: 22px;
}
.loginform_container .loginform_inp input {
  width: 100%;
  border: none;
  background-color: transparent;
  outline: none;
}

.loginform_container .loginform_inp_forgot {
  display: flex;
  justify-content: end;
}

.loginform_container .loginform_inp_forgot a {
  color: var(--light-gray);
  font-family: "DM Sans";
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 120% */
}

.loginform_container .loginform_submit {
  display: flex;
  justify-content: center;
  margin-top: 72px;
}

.loginform_container .loginform_submit_other_device {
  margin-top: 16px;
}
.loginform_container .loginform_submit button {
  border-radius: 5px;
  background: var(--primary-violet);
  padding: 7px 62px;
  color: var(--main-white);
  font-family: "DM Sans";
  font-size: 28px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 85.714% */
  border: 1px solid var(--primary-violet);
}

.loginform_container .loginform_submit_other_device button {
  padding: 10px;
  width: 100%;
}

.loginimg_container {
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
}

/******************************************** switch button css ***********************************************************/

.switch {
  position: relative;
  display: inline-block;
  width: 60px !important;
  height: 34px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.switch_inp input:checked + .slider {
  background-color: #2196f3;
}

.switch_inp input:focus + .slider {
  box-shadow: 0 0 1px #2196f3;
}

.switch_inp input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

/************************************************************ Custom Toast *****************************************************/

.custom-toast h3 {
  color: var(--primary-black);
  font-family: "DM Sans";
  font-size: 22px;
  font-style: normal;
  line-height: normal;
}
.custom-toast .custom-toast-div {
  display: flex;
  justify-content: end;
  gap: 12px;
  margin-top: 12px;
}

.custom-toast .custom-toast-div button {
  padding: 8px 14px;
  color: var(--main-white);
  border-radius: 6px;
  font-weight: 700;
  font-family: "DM Sans";
  font-size: 14px;
}

.custom-toast .custom-toast-div .toast-btn-yes {
  background-color: #11a63b;
  border: 1px solid #11a63b;
}
.custom-toast .custom-toast-div .toast-btn-no {
  background-color: #e30e00;
  border: 1px solid #e30e00;
}

.form_error_div {
  color: #dc3545;
  font-size: 0.875em;
  margin-top: 0.25rem;
  width: 100%;
}

/***************************************************** Notification card *************************************************/

.notification_card_container {
  border-radius: 5px;
  background: #fff;
  position: absolute;
  top: 30px;
  right: 0;
  width: 300px;
  padding: 12px 16px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  z-index: 9;
}

.notification_card_course .notification_card_course_title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
}

.notification_card_course .notification_card_course_title h4 {
  color: var(--primary-black);
  font-family: "DM Sans";
  font-size: 16px;
  font-style: normal;
  margin: 0;
  font-weight: 400;
  line-height: 24px; /* 150% */
}

.notification_card_course .notification_card_course_title svg {
  fill: var(--light-gray);
}

.notification_card_course .notification_card_course_info {
  display: flex;
  align-items: center;
  gap: 15px;
  margin-bottom: 4px;
}

.notification_card_course .notification_card_course_info span {
  color: var(--light-gray);
  font-family: "DM Sans";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 200% */
}

/****************************************************  Home Page Css **********************************************************/

.dashboard_schedulelist_container {
  padding: 20px;
  height: 57vh;
  overflow-y: auto;
}

.dashboard_schedulelist_list .dashboard_schedulelist_item {
  border-radius: 5px;
  background: #fff;
  padding: 10px;
  margin-bottom: 14px;
}

.dashboard_schedulelist_item .dashboard_schedulelist_title h3,
.dashboard_schedulelist_item .dashboard_schedulelist_title h4 {
  color: #0d2a41;
  font-family: "DM Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 24px */
  text-transform: capitalize;
}

.dashboard_schedulelist_item
  .dashboard_schedulelist_desc
  .dashboard_schedulelist_div {
  color: #7d8185;
  font-family: "DM Sans";
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: 150%; /* 18px */
}

.dashboard_schedulelist_desc .dashboard_schedulelist_div strong {
  color: #000;
  font-weight: 500;
}

.dashboard_schedulelist_item .dashboard_schedulelist_date {
  display: flex;
  justify-content: space-between;
}

.dashboard_schedulelist_item .dashboard_schedulelist_desc .dashboard_schedulelist_date span {
  display: inline-block;
  padding: 6px 14px;
  border-radius: 6px;
  background-color: #11a63b;
  color: #fff;
  font-family: "DM Sans";
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: 150%; /* 18px */
  font-weight: 700;
}

.dashboard_schedulelist_item .dashboard_schedulelist_date b {
  color: #7d8185;
}

.dashboard_schedule_container_loader {
  /* { height: "300px ", marginLeft: "29px", marginTop: "22px" } */
  height: 300px;
  margin-left: 29px;
  margin-top: 22px ;
  
}

.dashboard_schedule_container {
  padding: 15px;
}
.dashboard_schedule_container .dashboard_schedule_upcoming {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  border-radius: 7px 7px 0px 0px;
  background: linear-gradient(90deg, #024c87 37.9%, #75c1fd 100%);
  color: #fff;
  font-family: "DM Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: capitalize;
  padding: 6px 14px;
}
.dashboard_schedule_container .dashboard_schedule_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
}

.dashboard_schedule_container
  .dashboard_schedule_header
  .dashboard_schedule_heading
  h3 {
  color: #6e6d7a;
  font-family: DM Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.dashboard_schedule_container
  .dashboard_schedule_header
  .dashboard_schedule_heading
  h2 {
  color: #000;
  font-family: DM Sans;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.dashboard_schedule_container .dashboard_schedule_img {
  width: 297.041px;
  height: 215.671px;
  border-radius: 7px;
  background: url("../images/batch_img_eg.png");
  background-size: cover;
  position: relative;
}

.dashboard_schedule_container .dashboard_schedule_img .live_span {
  border-radius: 6px;
  background: #d80101;
  padding: 6px 18px;
  color: #fff;
  text-align: center;
  font-family: "DM Sans";
  font-size: 20.311px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: capitalize;
  position: absolute;
  bottom: 0;
  right: 0;
}

.dashboard_schedule_info .dashboard_schedule_desc h4 {
  color: #0d2a41;
  font-family: "DM Sans";
  font-size: 20.311px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 30.466px */
  text-transform: capitalize;
}

.dashboard_schedule_info .dashboard_schedule_desc .scrollbar-template {
  color: #7d8185;
  font-family: "DM Sans";
  font-size: 15.233px;
  font-style: normal;
  font-weight: 300;
  line-height: 150%; /* 22.849px */
  height: 142px;
  overflow-y: auto;
}

.dashboard_schedule_info .dashboard_schedule_desc .scrollbar-template strong {
  color: #000;
  font-weight: 500;
}

.dashboard_schedule_info {
  width: 55%;
}

.dashboard_schedule_info .dashboard_schedule_btn {
  display: flex;
  justify-content: end;
  margin-top: 12px;
  width: 100%;
}

.dashboard_schedule_info .dashboard_schedule_btn button {
  border-radius: 7px;
  border: 1px solid #576bad;
  color: #576bad;
  text-align: center;
  font-family: "DM Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: capitalize;
  padding: 6px 16px;
  background-color: transparent;
}
.dashboard_schedule_div {
  padding: 20px;
}
.dashboard_schedule_container {
  border-radius: 5px;
  background: #fff;
  box-shadow: 0px 0px 8px 0px #d9d9d9;
  padding: 16px;
  display: flex;
  align-items: center;
  gap: 16px;
}

.countdown_live_schedule {
  border-radius: 0px 0px 7px 7px;
  background: #024c87;
  position: absolute;
  bottom: 0;
  padding: 6px 12px;
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.countdown_live_schedule h6 {
  color: #fff;
  text-align: center;
  font-family: "DM Sans";
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: capitalize;
  margin-bottom: 0;
}

.homepage_div_page {
  height: 80vh;
  overflow-y: auto;
  overflow-x: hidden;
}

.dashboard_graph_container,
.dashboard_calendar_container {
  border-radius: 5px;
  background: #fff;
  padding: 10px;
  margin: 20px;
  margin-right: 30px;
  margin-left: 0;
}

.dashboard_graph_container .dashboard_graph_heading h2 {
  color: #576bad;
  font-family: "DM Sans";
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 30px */
}

.dashboard_graph_div {
  display: flex;
}

/*********************************************** Announcement Page CSS ***************************************************************/
.announcement_list_container {
  margin-top: 14px;
  padding: 8px 32px;
}

.announcement_list_container .announcement_list_items {
  border-radius: 5px;
  background: #fff;
  margin-bottom: 16px;
  padding: 8px 14px;
}

.announcement_list_container .announcement_list_items p {
  color: #939393;
  font-family: "DM Sans";
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 30px */
}

.announcement_list_items .announcement_list_item_info {
  display: flex;
  justify-content: end;
  gap: 14px;
  margin-top: 16px;
  align-items: center;
}

.announcement_list_items .announcement_list_item_info h4 {
  color: #989ea6;
  font-family: "DM Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 24px */
  margin: 0;
}

.announcement_list_items .announcement_list_item_info h3 {
  color: #46484e;
  font-family: "DM Sans";
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 30px */
  margin: 0;
}

.announcement_list_container .announcement_list_div {
  height: 76vh;
  overflow-y: auto;
  padding: 0px 10px;
}

.announcement_list_container .announcement_list_btn {
  position: fixed;
  right: 2%;
  bottom: 4%;
}

.announcement_list_container .announcement_list_btn button {
  width: 75px;
  height: 75px;
  border-radius: 50%;
  background-color: var(--primary-violet);
  border: none;
}

.announcement_list_container .announcement_list_btn button svg {
  font-size: 26px;
  fill: var(--primary-white);
}

.announcement-modal-body h2 {
  color: var(--primary-violet);
  text-align: center;
  font-family: "DM Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: capitalize;
  margin-bottom: 14px;
}

.announcement-modal-body .announcement-modal-inp textarea {
  border-radius: 5px;
  border: 1px solid var(--primary-white);
  margin-top: 18px;
  width: 100%;
  outline: none;
  padding: 10px;
}

.announcement-modal-body .announcement-modal-btn button {
  border-radius: 6px;
  background: #6aac29;
  padding: 4px 16px;
  border: 1px solid #6aac29;
  color: #fff;
  font-family: "DM Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%; /* 24px */
  text-transform: capitalize;
}

.announcement-modal-body .announcement-modal-btn {
  display: flex;
  justify-content: end;
  margin-top: 8px;
}

/******************************************** Certificate Modal CSSS ****************************************************/
.certificate-modal-body {
  padding: 10px;
}

.certificate-modal-body h2 {
  color: var(--primary-blue);
  text-align: center;
  font-family: "DM Sans";
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: capitalize;
  margin-bottom: 20px;
}

.certificate-modal-body .certificate-modal-inp label {
  color: var(--primary-blue);
  font-family: "DM Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  width: 100%;
}

.certificate-modal-body .certificate-modal-inp input {
  border-radius: 5px;
  border: 1px solid #d9d9d9;
  padding: 10px 12px;
  width: 100%;
  outline: none;
  background: #dddddd;
  cursor: not-allowed;
  opacity: 0.7;
}

.certificate-modal-body .certificate-modal-inp {
  margin-bottom: 14px;
}

.certificate-modal-radio .radio-container {
  color: #737373;
  font-family: "DM Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
}

.certificate-modal-radio span {
  color: var(--primary-blue);
  font-family: "DM Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.certificate-modal-body .certificate-modal-radio-text {
  color: var(--primary-blue);
  font-family: "DM Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 8px;
}

.certificate-modal-body .certificate-modal-radio {
  margin-bottom: 35px;
  display: flex;
  align-items: center;
  gap: 10px;
}

.certificate-modal-body .certificate-modal-radio input {
  scale: 1.5;
}

.certificate-modal-body .certificate-modal-submit {
  display: flex;
  justify-content: center;
}

.certificate-modal-body .certificate-modal-submit button {
  border-radius: 55px;
  background: #6aac29;
  border: 1px solid #6aac29;
  color: #fff;
  font-family: "DM Sans";
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: capitalize;
  padding: 8px 22px;
}

.disabled_certificatebtn {
  cursor: not-allowed;
  opacity: 0.5;
}

/****************************************************** Student Report CSS****************************************************************/
.student_reportform_container .student_reportform_div {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px 32px;
}

.student_reportform_container .student_reportform_div select {
  box-shadow: 0px 0px 4px 0px #afafafbf;
  background: #ffffff;
  outline: none;
  width: 50%;
  padding: 13px 12px;
  border: none;
  border-radius: 5px 0px 0px 5px;
}

.student_reportform_container .student_reportform_input {
  box-shadow: 0px 0px 4px 0px #afafafbf;
  background: #ffffff;
  width: 50%;
  padding: 9px 12px;
  border: none;
  border-radius: 0px 5px 5px 0px;
}

.student_reportform_container .student_reportform_input input {
  width: 84%;
  border: none;
  outline: none;
}

.student_reportform_container .student_reportform_input button {
  border-radius: 6px;
  font-family: DM Sans;
  font-size: 16px;
  font-weight: 400;
  line-height: 20.83px;
  text-align: center;
  background-color: #6aac29;
  padding: 3px 14px;
  border: none;
  color: #fff;
}

.student_reportform_container {
  margin-top: 24px;
}

.report_view_table_div {
  height: 68vh;
  margin-top: 0;
}

.student-report-body-div .student-report-body-details h4 {
  font-size: 20px;
}

.student-report-body-div {
  margin-top: 22px;
  height: 41vh;
  overflow-y: auto;
}

.student-report-body-div .student-report-body-details h5 {
  font-size: 16px;
  color: gray;
}

.resources_card .resource_rename_inp {
  outline: none;
  border: none;
  border-bottom: 2px solid #024c87;
}

.resources_card .resource_card_share_list button,
.resources_card .resource_card_color_list button {
  padding: 8px 12px;
  background-color: #024c87;
  color: #fff;
}

.resources_card  .resource_color_option_colors{
  box-shadow: none;
  background-color: transparent;
}

.resources_card_container .resources_card_container_flex {
  display: flex;
  flex-wrap: wrap;
  column-gap: 20px ;
  width: 100%;
  row-gap: 25px;
}

.ql-editor * {
  background: transparent !important;
}


.resource_table_icon .resource_icon_img {
  height: 24px;
  width: 24px;
}

.switch_inp_schedule {
  scale: 0.6;
}

.work_batch_list_container .work_batch_list_options {
  margin-top: 18px;
  margin-bottom: 10px;
  display: flex;
  justify-content: end;
  gap: 10px ;
  
}

.work_batch_list_container .work_batch_list_div {
   margin-top: 15px ;
   height: 67vh;
   overflow-y: auto;
   overflow-x: hidden;
   padding-right: 12px ;
}

.work_batch_list_container .work_batch_list_options button {
  padding: 3px 6px ;
  gap: 16px;
  border-radius: 4px;
  font-family: DM Sans;
  font-weight: 500;
  font-size: 14px;
  line-height: 18.23px;
  letter-spacing: 0%;
  text-align: center;
  color: var(--light-gray);
  border: none;
  }

.work_batch_list_container .work_batch_list_options button.active {
background-color: #E1F2FF;
color:var(--primary-violet) ;
border: 1px solid #E1F2FF ;

}


.work_batch_list_container .work_batch_list_div .work_batch_list_card {
background-color: #FFFFFF;
padding: 24px 18px  ;
border-radius: 8px;
margin-bottom: 18px ;

}

.work_batch_list_container {
  padding: 24px 32px;
}

.work_batch_list_container .work_batch_list_div .work_batch_list_card img  {
  height: 178px;
  width: 100%;
  object-fit: cover;
  border-radius: 6px;
}

.work_batch_list_container .work_batch_list_div .work_batch_list_card h4 {
  font-family: DM Sans;
font-weight: 600;
font-size: 16px;
line-height: 20.83px;
letter-spacing: 0%;
color: var(--primary-blue);
margin-top: 9px;
margin-bottom: 18px;
}

.work_batch_list_container .work_batch_list_div .work_batch_list_card button {
  border: 1px solid #C9E2FF;
  width: 100%;
  border-radius: 7px ;
  font-family: DM Sans;
font-weight: 400;
font-size: 16px;
line-height: 20.83px;
letter-spacing: 0%;
text-align: center;
color: #0057FF;
background-color: transparent;
padding: 8px 12px ;
}

.work_batch_list_container .work_batch_list_div .work_batch_list_card button:hover{
  background-color: #0057FF;
  color: #fff;
}


.student-report-container {
  display: flex;
  flex-direction: column;
}
.student-report-header {
  background-color: #f7f8fe;
  color: #0d2a41;
  display: grid;
  grid-template-columns: 1fr 0.41fr 1fr 1fr;
}
.student-report-content {
  display: grid;
  grid-template-columns: 1fr 0.4fr 1fr 1fr;
  border-bottom: 1px solid rgb(69, 75, 99);
}
.student-report-content:last-child {
  border-bottom: none;
}
 
.student-report-content {}
 
.student-report-header p, .student-report-content p {
  margin-bottom: 0;
  border-right: 1px solid rgb(69, 75, 99);
  padding: 4px 9px;
}
.student-report-header p:last-child, .student-report-content p:last-child {
  border-right: none;
}
 
.student-report-header p {
  border-bottom: none;
  border-right: none;
}
 
.modal-excel-assignment-report-btn {
  background-color: #fff;
  border: 1px solid #808080;
  display: flex;
  align-items: center;
  gap: 5px;
  outline: none;
  padding: 4px 10px;
  border-radius: 5px;
  transition: all 300ms ease;
}
 
.modal-excel-assignment-report-btn:hover {
  background: #f7f8fe;
}


.compose_email_input  .compose_email_input_student {
  width: 100%;
  height: 100%;
  padding: 8px;
  border: 1px solid #cccccc;
  outline: none;
  border-radius: 4px ;
}


.email_select_custom_option {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 12px ;

}

.email_select_custom_option svg {
  fill: #e30e00;
}

.email_select_custom_option:hover{
  background-color: rgb(211, 211, 211)
}