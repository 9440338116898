@media screen and (max-width: 1200px) {
  .header_container .header_profile_details .header_profile_humberger {
    display: block;
  }

  .sidebar_container {
    display: none;
  }

  .mobile_sidebar {
    position: absolute;
    left: 0;
    top: 0;
    width: 300px;
    height: 100vh;
    transform: translateX(-100%);
    transition: all 0.3s;
    z-index: 999;
  }

  .mobile_sidebar.active {
    transform: translateX(0);
    display: block;
  }
}

@media screen and (max-width: 990px) {
  .resources_card_container .resources_card_container_flex {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media screen and (max-width: 768px) {
  .student_reportform_container .student_reportform_input {
    display: flex;
  }

  .dashboard_graph_container,
  .dashboard_calendar_container {
    margin: 20px;
  }

  .video_view_container {
    padding: 20px;
  }

  .batch_header_container .batch_header_inp {
    padding: 15px;
  }

  .video_view_container .video_view_right {
    width: 100%;
    padding: 0;
  }

  .loginimg_container {
    display: none;
  }

  .login_container .row {
    height: 80%;
  }
}

@media screen and (max-width: 576px) {
  /*  */
  .email_chats_content {
    height: calc(100vh - 138px);
  }

  .email_chat_list_content span {
    align-items: flex-start;
    flex-direction: column;
    gap: 0;
  }

  .email_chat_list_content span h5 {
    margin-bottom: 4px;
  }

  .email_chat_list_content span .active {
    margin-bottom: 4px;
  }

  /*  */
  .resources_card_container .resources_card_container_flex {
    grid-template-columns: repeat(1, 1fr);
  }

  .student_reportform_container .student_reportform_div {
    flex-direction: column;
    gap: 1px;
  }

  .student_reportform_div .student_reportform_div_select {
    width: 100%;
    border-radius: 0;
  }

  .student_reportform_container .student_reportform_input {
    width: 100%;
    border-radius: 0px 0px 5px 5px;
  }

  /*  */
  .batchlist_cards .batchlist_card_items {
    flex-direction: column;
  }

  .batchlist_card_items .batchlist_card_items_left {
    flex-direction: column;
  }

  .batchlist_card_items .batchlist_card_items_left img {
    width: 100%;
  }

  .batchlist_card_items .batchlist_card_items_right {
    align-items: baseline;
  }

  .announcement_list_items .announcement_list_item_info {
    flex-direction: column;
    align-items: baseline;
    gap: 5px;
  }

  .batchlist_card_item_options .batchlist_card_option_div {
    right: unset;
    /* left: 0; */
  }

  .module_viewcards_container .module_viewcards_item {
    flex-direction: column;
  }

  .module_viewcards_container .module_viewcards_item_details {
    width: 100%;
    height: auto;
    gap: 20px;
  }

  .module_viewcards_container {
    padding-inline: 30px;
  }

  .dashboard_schedule_container {
    flex-direction: column;
  }

  .dashboard_schedule_info {
    width: 100%;
  }

  .dashboard_schedule_container .dashboard_schedule_img {
    width: 100%;
  }
  .dashboard_schedule_container_loader {
    margin: 10px 25px;
  }
  .email_chats_content_profile_container .email_chats_content_profile {
    flex-direction: column;
    gap: 6px ;
  }
 
}

@media screen and (max-width: 426px) {
  .email_chat_list_content span h6 {
    text-align: right;
  }
  .email_header_right_side .email_header_search_input {
    width: 200px;
  }
  .email_chats_content_profile_container {
    margin-top: 1px;
    padding-block: 10px;
  }
  .email_chats_content_profile p {
    margin-bottom: 0;
  }
  .compose_email_box {
    width: 100%;
    padding-inline: 10px;
  }
  .compose_email_top span svg:first-child {
    display: none;
  }
  .email_header {
    position: static;
  }
  .email_chat_list_container {
    padding-top: 0;
    padding-inline: 10px;
  }
  .student_batch_select button {
    padding-block: 9px;
    font-size: 12px;
    width: 205px;
  }
  .student_batch_select {
    padding: 10px;
  }

  /* end */
  .profile_form_container .profile_form_div .profile_form_submit {
    margin-bottom: 20px;
  }

  .header_container .header_profile_details {
    gap: 10px;
    padding-right: 0;
  }

  .qna_items .qna_header {
    align-items: flex-start;
  }

  .qna_items .program_preview_info {
    flex-direction: column;
    align-items: flex-start;
  }

  .qna_accordian_div .accordion-button {
    padding: 10px;
  }

  .qna_accordian_container .qna_accordian_options {
    flex-direction: column;
    gap: 15px;
  }

  .qna_accordian_select {
    width: 100%;
  }

  .header_container {
    flex-direction: column-reverse;
    align-items: flex-end;
    gap: 10px;
  }

  .header_container .header_breadcrumb {
    display: none;
  }

  .table-template-style tbody tr td {
    padding: 5px;
    text-align: center;
    min-width: 130px;
  }

  .allassignment_table_tag th {
    min-width: 130px;
  }

  .module_card_container {
    padding-inline: 30px;
  }

  .module_card_container .module_card_item img {
    object-fit: contain;
  }

  .module_card_container .row {
    height: auto;
  }

  .module_header_container .module_header_navs {
    background-color: var(--main-white);
    display: flex;
    align-items: center;
    padding-bottom: 0;
    justify-content: center;
    padding: 10px 0;
  }

  .table-template-style thead tr th {
    text-align: center;
    min-width: 90px;
  }

  .module_student_container {
    padding-inline: 30px;
  }

  .dashboard_schedulelist_container {
    height: auto;
  }

  .profile_form_container {
    height: auto;
  }

  .qna_accordian_container .qna_accordian_scroll {
    height: auto;
  }

  .allassignment_table_sort .allassignment_table_sort_list {
    right: 0;
    left: unset;
  }

  .assignment_table_div {
    height: auto;
  }

  .dashboard_schedulelist_item .dashboard_schedulelist_date {
    flex-direction: column;
    gap: 15px;
  }

  .homepage_div_page {
    height: auto;
  }

  .dashboard_schedule_container .dashboard_schedule_img .live_span {
    bottom: 10px;
    right: 10px;
  }

  .module_videolist_div {
    height: auto;
  }

  .module_video_cards_container {
    flex-direction: column;
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 10px;
  }

  .module_video_cards_container .module_video_cards_title {
    align-items: flex-start;
  }

  .module_video_cards_container .module_video_cards_description h3 {
    height: 100px;
    overflow-y: auto;
  }

  .video_wrapper_container img {
    width: 100%;
  }

  .module_video_cards_video {
    width: 100%;
  }

  .video_wrapper_container {
    width: 100%;
  }

  .video_wrapper_container img {
    width: 100% !important;
    height: 230px !important;
    object-fit: contain;
    background: white;
  }

  .video_view_container .video_view_right {
    height: auto;
    width: 100%;
    padding: 0;
  }

  .batchlist_container .batchlist_cards {
    height: auto;
  }

  .video_view_container {
    padding: 20px;
  }

  .video_view_right .video_view_right_item {
    flex-direction: column;
  }

  .video_view_container .video_view_left {
    height: auto;
  }

  .video_wrapper_container video {
    height: auto !important;
    width: 100% !important;
  }

  .submission-modal-body-inp .submission-modal-body-rating ul li {
    padding-inline: 8px;
  }

  .batch_header_container {
    flex-direction: column;
  }

  .batch_header_container .batch_header_inp {
    width: 100%;
  }

  .batch_header_container .batch_header_inp svg {
    position: absolute;
    top: 35%;
    left: 9%;
    height: 18px;
    width: 18px;
  }

  .module_student_container .module_student_navs {
    justify-content: center;
  }

  .module_student_container .module_student_table {
    height: auto;
  }

  .module_video_header_container .video_header_addvideo button {
    margin-right: 0;
  }

  .assignment_view_table_div {
    height: auto;
  }

  .assignment_header_container {
    flex-direction: column;
    gap: 10px;
  }

  .module_video_header_container {
    flex-direction: column;
    gap: 10px;
  }

  .module_video_cards_video img {
    width: 100%;
  }

  .module_student_container .module_student_table td button {
    font-size: 16px;
  }

  .qna_accordian_div .qna_header .qna_header_heading {
    width: 100%;
    overflow-y: auto;
  }

  .upload-video-modal-selectfile .upload-video-selectfile-div p {
    font-size: 14px;
  }

  .upload-video-modal-body .dropzone {
    padding: 15px;
  }

  .upload-video-modal-body .upload-video-modal-file-select {
    padding: 10px;
  }

  .upload-video-modal .upload-video-modal-body {
    padding-inline: 10px;
  }

  .upload-video-modal-file-select .upload-video-modal-file-txt {
    overflow: hidden;
  }
}
