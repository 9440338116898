.header_container .header_profile_details .header_profile_humberger {
  display: none;
}

.sidebar_cross_menu {
  color: white;
  position: absolute;
  top: 15px;
  right: 15px;
  font-size: 25px;
}

.student_reportform_container .student_reportform_div {
  margin: 0px 32px;
  padding: 0;
}

.student_reportform_container .student_reportform_div {
  justify-content: flex-start;
}

.student_reportform_container .student_reportform_div select {
  box-shadow: 0px 0px 4px 0px rgba(175, 175, 175, 0.75);
  color: rgba(140, 140, 140, 1);
  height: 45px;
  width: 100%;
  -webkit-appearance: none;
}

.student_reportform_div .student_reportform_div_select {
  position: relative;
  width: 50%;
  font-size: 16px;
}

.student_reportform_div .student_reportform_div_select_dropdown {
  position: absolute;
  top: 14px;
  right: 14px;
  color: gray;
  font-size: 18px;
}

.student_reportform_container .student_reportform_input {
  height: 45px;
  font-size: 16px;
  box-shadow: 0px 0px 4px 0px rgba(175, 175, 175, 0.75);
  width: 50%;
}

/*  */
.module_student_active_deactive {
  border: 2px solid gray;
  border-radius: 6px;
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.module_student_container
  .module_student_table
  td
  .module_student_active_deactive
  button {
  font-size: 16px;
  width: 100px;
  /* height: 28px; */
  padding: 2px 10px;
  color: black;
  border: 0;
  border-radius: 4px;
  background-color: transparent;
}

.module_student_container
  .module_student_table
  td
  .module_student_active_deactive
  .active {
  background-color: #02b902;
  color: white;
}

.module_student_container
  .module_student_table
  td
  .module_student_active_deactive
  .deactive {
  background-color: rgb(202, 52, 25);
  color: white;
}

/*  */

.resources_header {
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 14px 22px;
}

.resources_header .resources_header_heading {
  display: flex;
  align-items: center;
  gap: 10px;
}

.resources_header_heading h2 {
  font-weight: 500;
  color: rgba(2, 76, 135, 1);
  font-size: 24px;
  margin: 0;
}

.resources_header_heading svg {
  color: rgba(2, 76, 135, 1);
  font-weight: 400;
}

.resources_header .resources_header_btn {
  background: rgba(2, 76, 135, 1);
  color: white;
  border-radius: 5px;
  border: 0;
  outline: 0;
  padding: 8px 20px;
  cursor: pointer;
  font-size: 16px;
}

.resources_card_container {
  margin: 18px 22px;
  gap: 20px;
  height: 75vh;
  overflow: auto;
  /* display : flex;
  align-items : center;
  justify-content : center; */
}

.resources_card_container .loader_container {
  height: 100%;
  display: flex;
}

.resources_card_container .resources_card_container_flex {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  width: 100%;
  /* height: 100%; */
  /* align-content: center; */
}

.resources_card_container .resources_card {
  /* width: 23%; */
  background-color: white;
  border-radius: 8px;
  padding: 10px 10px;
  position: relative;
  display: flex;
  cursor: pointer;
  align-items: center;
  justify-content: space-between;
}

.resources_card .resource_card_rename_options svg {
  cursor: pointer;
  color: rgba(89, 89, 89, 1);
  /* width: 40px; */
}

.resources_card .resource_card_rename_options .resource_card_rename_option {
  position: absolute;
  right: 3px;
  top: 63px;
  /* padding: 10px 15px; */
  background-color: white;
  width: 190px;
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  /* overflow: hidden; */
  /* gap: 18px; */
  z-index: 99;
}

.resource_card_rename_options .resource_card_rename_option h4 {
  color: rgba(51, 51, 51, 1);
  font-size: 16px;
  cursor: pointer;
  font-weight: 400;
  display: flex;
  align-items: center;
  gap: 20px;
  position: relative;
  padding: 12px 16px;
}

.resource_card_rename_options .resource_card_rename_option h4:hover {
  background: rgba(212, 236, 255, 1);
}

.resource_card_rename_option h4 .resource_share_inp,
.resources_card .resource_color_option {
  background-color: white;
  padding: 10px;
  width: 250px;
  position: absolute;
  right: -255px;
  top: 0;
  z-index: 99;
  opacity: 0;
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
}

.resources_card .resource_color_option {
  width: 257px;
  right: -260px;
}

.resource_share_inp label {
  margin-bottom: 9px;
  font-size: 14px;
}

.resource_card_rename_option
  .resource_card_share_list:hover
  .resource_share_inp {
  opacity: 1;
}

.resource_card_rename_option h4 img {
  width: 20px;
  height: 20px;
}

.resources_card .resources_card_img {
  display: flex;
  align-items: center;
  justify-content: center;
  /* background-color: rgba(251, 251, 251, 1); */
  /* width: 90%; */
  margin: auto;
  height: 150px;
  margin-bottom: 10px;
}

.resources_card img {
  width: 82px;
  height: 82px;
}

.resources_card h4 {
  font-size: 18px;
  color: rgba(87, 107, 173, 1);
  font-weight: 500;
  margin: 0;
}

.resources_card p {
  font-size: 16px;
  color: rgba(217, 217, 217, 1);
  font-weight: 300;
  margin: 0;
}

.resources_card h3 {
  color: rgba(140, 140, 140, 1);
  font-weight: 400;
  font-size: 20px;
}

.resources_card button {
  border: 1px solid rgba(201, 226, 255, 1);
  color: rgba(0, 87, 255, 1);
  font-weight: 400;
  font-size: 16px;
  background: transparent;
  outline: 0;
  border-radius: 7px;
  width: 100%;
  padding: 8px 0px;
  margin-top: 10px;
}

.resource_detail_container_div {
  height: calc(100vh - 150px);
  overflow: auto;
  padding: 25px;
  --bs-gutter-x: 0rem;
}

.dashboard_resources_detail_content_cards {
  /* padding: 25px; */
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  gap: 20px;
}

.dashboard_resources_detail_content_card {
  width: 32%;
  background-color: white;
  border-radius: 12px;
  padding: 12px;
}

.dashboard_resources_detail_content_card
  .dashboard_resources_detail_content_card_head {
  display: flex;
  align-items: center;
  gap: 24px;
  font-size: 20px;
  font-weight: 400;
  color: rgba(92, 92, 92, 1);
}

.dashboard_resources_detail_content_card img {
  width: 100%;
  margin: 20px 0px 10px 0px;
}

.dashboard_resources_detail_content_card span {
  display: flex;
  align-items: center;
  gap: 10px;
}

.dashboard_resources_detail_content_card span h4 {
  margin: 0;
  color: rgba(183, 183, 183, 1);
  font-weight: 400;
  font-size: 18px;
}

.dashboard_resources_detail_content_card span img {
  width: 38px;
  height: 38px;
  margin: 0;
}

/* Start End */

/* .dashboard_resources_detail_content_card */

.dashboard_resources_detail_content_table table {
  width: 100%;
  color: rgba(51, 51, 51, 1);
}

.dashboard_resources_detail_content_table table thead {
  background-color: white;
}

.dashboard_resources_detail_content_table table tbody {
  background-color: white;
}

.dashboard_resources_detail_content_table table thead tr th {
  padding: 10px;
  font-size: 16px;
  font-weight: 400;
  border-bottom: 1px solid rgba(213, 213, 213, 1);
}

.dashboard_resources_detail_content_table table tbody tr td {
  width: 33%;
  padding: 10px;
  font-size: 16px;
  font-weight: 400;
  border-bottom: 1px solid rgba(213, 213, 213, 1);
}

.dashboard_resources_detail_content_table
  table
  tbody
  tr
  td
  .resource_table_icon {
  display: flex;
  align-items: center;
  gap: 10px;
}

.dashboard_resources_detail_content_table table tbody tr td svg {
  width: 20px;
}

.resource_card_rename_options h4 .resource_card_detail_list {
  position: absolute;
  border-radius: 8px;
  right: -328px;
  top: 0;
  background-color: white;
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);
  padding: 8px 13px;
  z-index: 99;
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 325px;
}

.resource_card_detail_list span {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.resource_card_detail_list span h3 {
  color: rgba(51, 51, 51, 1);
  font-size: 18px;
  font-weight: 600;
  margin: 0;
}

.resource_card_detail_list span h5 {
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 0;
}

.resource_color_option .resource_color_option_colordiv div:nth-child(1) {
  position: static !important;
}

.resource_color_option
  .resource_color_option_colordiv
  .flexbox-fix
  div:first-child {
  position: absolute !important;
}

.resource_color_option .resource_color_option_colordiv .compact-picker {
  padding: 0 !important;
}

.resources_card_container
  .resources_card:nth-child(4n)
  .resource_card_rename_option
  h4
  .resource_share_inp {
  left: -255px;
}

.resources_card_container
  .resources_card:nth-child(4n)
  .resource_card_rename_options
  h4
  .resource_card_detail_list {
  left: -328px;
}

.resources_card_container
  .resources_card:nth-child(4n)
  .resource_card_color_list
  .resource_color_option {
  left: -262px;
}

.dashboard_resources_detail_content_table
  table
  tbody
  tr
  td
  .resource_table_icon:hover {
  text-decoration: underline;
  cursor: pointer;
}

/* start_end */

.resources_not_upload_container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  grid-column: 1/-1;
}

.resource_not_upload {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  /* padding-top: 50px; */
}

.resource_not_upload img {
  width: 390px;
  height: 280px;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

.resource_not_upload h4 {
  font-size: 20px;
  font-weight: 500;
  color: rgba(51, 51, 51, 1);
}

/* start */
.email_container {
  position: relative;
}

.email_header {
  background-color: white;
  display: flex;
  align-items: center;
  padding: 16px 20px;
  justify-content: space-between;
  box-shadow: 0px 0px 4px 0px #d9d9d9;
  position: fixed;
  width: -webkit-fill-available;
}

.email_header h2 {
  display: flex;
  align-items: center;
  font-size: 24px;
  font-weight: 500;
  margin: 0;
}

.email_header .email_header_right_side {
  display: flex;
  align-items: center;
  gap: 20px;
}

.email_header_right_side .email_header_search_input {
  /* width: 300px;
  height: 40px;
  position: relative;
  box-shadow: 0px 0px 4px 0px #d9d9d9;
  border-radius: 8px;
  overflow: hidden; */
  /*  */
  font-size: 14px;
  outline: none;
  width: 300px;
  padding: 8px 12px;
  border-radius: 6px;
  border: 1px solid rgba(217, 217, 217, 1);
  font-weight: 400;
  position: relative;
  color: rgba(154, 163, 171, 1);
}

.email_header_search_input .search_icon {
  position: absolute;
  color: #717171;
  top: 9px;
  left: 13px;
}

.email_header_search_input input {
  width: 100%;
  border: 0;
  height: 100%;
  padding-left: 30px;
  outline: 0;
  color: #bbbbbb;
  font-size: 16px;
  font-weight: 400;
}

.email_header_right_side .email_header_btn {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #717171;
  border-radius: 4px;
  box-shadow: 0px 0px 4.4px 0px #d9d9d9;
}

.email_chat_list_container {
  padding: 10px;
  background-color: white;
  height: calc(100vh - 70px);
  padding-top: 80px;
}

.email_chat_list_container .email_chat_list {
  display: flex;
  gap: 15px;
  cursor: pointer;
  padding: 20px 16px;
}

.email_chat_list img {
  width: 52px;
  height: 51px;
  border-radius: 16px;
  object-fit: cover;
}

.email_chat_list_content p {
  margin-top: 5px;
  margin-bottom: 0;
  font-size: 16px;
  font-weight: 400;
  color: #262626;
  text-transform: none;
}

.email_chat_list_content span {
  display: flex;
  align-items: center;
  gap: 10px;
}

.email_chat_list_content span .active {
  background-color: #024c87;
  padding: 2px 12px;
  border-radius: 4px;
  color: #fff;
}

.email_chat_list_content span h3 {
  margin: 0;
  font-weight: 600;
  font-size: 18px;
  color: #262626;
}

.email_chat_list_content span h6 {
  color: #262626;
  font-size: 12px;
  margin: 0;
  font-weight: 300;
}

.email_chat_cta {
  position: fixed;
  background-color: #024c87;
  width: 65px;
  height: 65px;
  border-radius: 50%;
  right: 5%;
  cursor: pointer;
  bottom: 10%;
  color: white;
  display: flex;
  font-size: 28px;
  align-items: center;
  justify-content: center;
}

.compose_email_box {
  box-shadow: 0px 0px 4px 0px #d9d9d9;
  position: fixed;
  top: 0;
  right: 0;
  background-color: white;
  padding: 30px 35px;
  width: 600px;
  height: 100vh;
  overflow-y: auto;
  /* transition: all 0.5s ease; */
}

.compose_email_box.active {
  position: absolute;
  width: 100%;
  transition: all 0.5s ease;
}

.compose_email_box .compose_email_top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}

.compose_email_top h2 {
  font-size: 24px;
  font-weight: 700;
  color: #2d2c2c;
}

.compose_email_top span {
  display: flex;
  align-items: center;
  cursor: pointer;
  gap: 20px;
}

.compose_email_input .email_to {
  border: 0;
  border-bottom: 1px solid #d9d9d9;
  outline: 0;
  padding: 5px 0 18px 10px;
  color: #a6a7a7;
  font-size: 20px;
  width: 100%;
  font-weight: 400;
}

.compose_email_input h2 {
  font-size: 20px;
  font-weight: 500;
  color: #2d2c2c;
  margin-top: 20px;
}

.compose_email_input .compose_email_search_input {
  position: relative;
  box-shadow: 0px 0px 4px 0px #d9d9d9;
  border-radius: 8px;
  margin-bottom: 20px;
}

.compose_email_search_input input {
  border: 0;
  outline: 0;
  color: #bbbbbb;
  font-size: 16px;
  padding: 10px 0 10px 8px;
}

.compose_email_search_input .search_icon {
  margin-left: 20px;
  color: #717171;
}

.compose_email_input .email_sub {
  border: 0;
  border-bottom: 1px solid #d9d9d9;
  outline: 0;
  padding: 13px 0 9px 10px;
  color: #a6a7a7;
  font-size: 20px;
  width: 100%;
  font-weight: 400;
}

.compose_email_box .compose_email_bottom {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;
}

.compose_email_bottom button:first-child {
  border: 1px solid #024c87;
  outline: 0;
  border-radius: 8px;
  padding: 8px 20px;
  font-size: 14px;
  font-weight: 600;
  background: transparent;
  color: #024c87;
}

.compose_email_bottom button:last-child {
  border: 1px solid #2da562;
  outline: 0;
  padding: 12px 20px;
  border-radius: 50px;
  background-color: #2da562;
  color: white;
  font-size: 16px;
  font-weight: 400;
}

.compose_email_template {
  background-color: white;
  position: absolute;
  padding: 20px 37px;
  width: 100%;
  bottom: 0;
  left: 0;
  box-shadow: 0px -1px 4px 0px #9a9a9a40;
}

.compose_email_template .compose_email_template_top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
  cursor: pointer;
}

.compose_email_template_top h2 {
  color: #0f0f0f;
  font-size: 20px;
  font-weight: 500;
}

.compose_email_template .compose_email_search_input {
  box-shadow: 0px 0px 4px 0px #d9d9d9;
  border-radius: 8px;
}

.compose_email_search_input .search {
  margin-left: 20px;
  color: #717171;
}

.compose_email_search_input input {
  padding: 10px 0 10px 10px;
  color: #bbbbbb;
  width: 95%;
}

.compose_email_template .compose_email_save {
  margin-top: 20px;
}

.compose_email_save h2 {
  font-size: 20px;
  font-weight: 500;
  color: #0f0f0f;
  margin-bottom: 20px;
}

.compose_email_save .compose_email_save_option {
  display: flex;
  flex-direction: column;
}

.compose_email_save_option span {
  display: flex;
  gap: 10px;
  align-items: center;
  font-size: 16px;
  font-weight: 500;
  color: #0f0f0fbf;
}

.compose_email_save button {
  background-color: #024c87;
  color: white;
  padding: 12px 24px;
  width: 100%;
  border: 0;
  outline: 0;
  font-size: 15px;
  border-radius: 8px;
  margin-top: 20px;
  font-weight: 600;
}

.compose_email_edit_text .ql-container {
  min-height: 150px;
}

.email_chats_content .email_chats_content_top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
}

.email_chats_content_top .email_chats_content_head {
  display: flex;
  align-items: center;
  gap: 20px;
}

.email_chats_content_head .arrow_left {
  color: #0000008a;
}

.email_chats_content_head h2 {
  color: #202124;
  font-size: 28px;
  font-weight: 400;
  margin: 0;
}

.email_chats_content_top .trash_icon {
  color: #0000008a;
  cursor: pointer;
}

.email_chats_content_profile_container {
  padding: 0 20px;
  background-color: white;
  padding-top: 20px;
  margin-top: 67px;
  /* height: 400px;
  overflow-y: auto; */
}

.email_chats_content .email_chats_content_profile_container p {
margin-bottom: 0;
}

.email_chats_content .email_chats_content_profile_container .email_chats_content_subject {
  font-weight: 500;
  font-size: 18px ;
}

.email_chats_content_profile_container .email_chats_content_profile {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.email_chats_content_profile .email_chats_content_profile_left {
  display: flex;
  gap: 10px;
}

.email_chats_content_profile_left img {
  width: 40px;
  height: 40px;
}

.email_chats_content_profile_left .email_chats_content_mail h4 {
  font-size: 14px;
  color: #202124;
  font-weight: 400;
  margin-bottom: 4px;
}

.email_chats_content_mail .active {
  background-color: #024c87;
  padding: 2px 12px;
  border-radius: 4px;
  color: #fff;
  font-size: 18px;
  width: fit-content;
}

.email_chats_content_mail h4 span {
  color: #0000008a;
  font-size: 14px;
  font-weight: 400;
  margin-left: 4px;
}

.email_chats_content_mail h5 {
  display: flex;
  align-items: center;
  font-size: 14px;
  color: #0000008a;
  font-weight: 400;
  cursor: pointer;
  position: relative;
}

.email_chats_content_mail h5 .show_batch_modal_div {
  position: absolute;
  top: 23px;
  right: -250px;
  width: 380px;
  box-shadow: 0px 0px 4px 0px #d9d9d9d4;
  padding: 24px;
  background-color: white;
}

.student_batch_select {
  display: flex;
  align-items: center;
  gap: 20px;
}

.student_batch_select button {
  width: 190px;
  padding: 7px 12px;
  border: 2px solid rgba(152, 152, 152, 1);
  background-color: rgba(195, 192, 192, 1);
  border-radius: 4px;
  display: flex;
  align-items: center;
  gap: 10px;
  font-weight: 500;
}

.student_batch_select button span {
  width: 18px;
  height: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  border-radius: 4px;
}

.student_batch_select button span img {
  width: 12px;
}

.student_batch_select .css-b62m3t-container {
  width: -webkit-fill-available;
}
/* end */



.work_batch_list_container .work_batch_list_options {
  margin-top: 0;
}

.assignment_share_modal_body {
  position: relative;
}

.assignment_share_modal_body .assignment_share_modal_inp {
  display: flex;
  flex-direction: column;
  gap: 5px;
  margin-top: 15px;
}

.assignment_share_modal_inp label {
  color: #024c87;
}

.assignment_share_modal_inp input {
  border: 1px solid hsl(0, 0%, 80%);
  border-radius: 4px;
  color: hsl(0, 0%, 50%);
  padding: 8px;
}

.assignment_share_modal_body .close_icon {
  position: absolute;
  top: -15px;
  right: 0;
  font-size: 20px;
  cursor: pointer;
  color: #2a2a2a;
}

.assignment_share_modal_body button {
  border-radius: 22px;
  background: var(--primary-blue);
  border: 1px solid var(--primary-blue);
  padding: 8px 12px;
  color: var(--main-white);
  text-align: center;
  font-family: "DM Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: capitalize;
  display: block;
  margin: auto;
  margin-top: 12px;
}

/*  */
.email_header {
  position: static;
}

.email_chat_list_container {
  padding-top: 0;
  height: calc(100vh - 138px);
  overflow: auto;
}

.email_chats_content_profile_container {
  margin-top: 1px;
  background-color: transparent;
}
.assignment_share_modal_body button {
  margin-top: 40px;
}


.email_header .email_header_right_side {
  margin-right: 12px;
}
.compose_email_edit_text .ql-container {
  min-height: 315px;
}
.email_chat_list_container .not-found-message {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.email_chat_list_container .not-found-message {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding-top: 40px;
}
.email_chat_list_container .not-found-message img {
  width: 300px;
}

.email_chat_list_container .not-found-message p {
  font-size: 18px;
}

.email_chats_content {
  height: calc(100vh - 133px);
  background-color: rgb(245, 245, 245);
}
.email_chats_content .email_chats_content_profile_container p {
  margin-right: 10px;
}
.email_chats_content_profile_left img {
  width: 52px;
  height: 51px;
  border-radius: 16px;
}

/* enter */