/* Styles for the calendar container */
.calendar-container {
  font-family: Arial, sans-serif;
  max-width: 400px;
  margin: 0 auto;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

/* Styles for the calendar heading */
.calendar-heading {
  text-align: center;
  margin-bottom: 20px;
}

/* Styles for the navigation buttons */
.react-calendar__navigation button {
  background-color: #024c87 !important;
  border: none;
  color: #fff;
  padding: 5px 10px;
  border-radius: 5px;
  cursor: pointer;
}

.react-calendar__navigation button:hover {
  background-color: #024c87 !important;
}

/* Styles for the weekdays */
.react-calendar__month-view__weekdays {
  display: flex;
  justify-content: space-between;
}

/* Styles for the individual weekday */
.react-calendar__month-view__weekdays__weekday abbr {
  text-decoration: none;
  color: #555;
}

/* Styles for the days in the calendar */
.react-calendar__month-view__days {
  display: grid !important;
  grid-template-columns: repeat(7, 1fr);
  gap: 5px;
}

/* Styles for the individual day */
.react-calendar__tile {
  border: none;
  padding: 10px;
  text-align: center;
  border-radius: 8px;
  cursor: pointer;
}

.react-calendar__tile--active {
  background-color: #fff !important;
  color: #000;
  border-radius: 8px;
}

/* Styles for the selected dates */
.selected {
  background-color: #024c87 !important;
  color: #fff;
  border-radius: 8px;
}
.react-calendar__navigation {
  gap: 4px;
}
.react-calendar {
  padding: 18px;
  border-radius: 8px;
  border: none;
  width: 100%;
}
/* Styles for the today's date */
.react-calendar__tile--now {
  background-color: #035bbf !important;
  color: #fff;
  border-radius: 8px;
  /* text-decoration: underline; */
}
.react-calendar__tile--hasActive {
  /* background-color: #fff !important ; */
  /* color: #fff !important; */
  background-color: #024c87 !important ;
  color: #fff !important;
}
.react-calender-selected {
  /* background-color: #024c87 !important ; */
  color: #1C1E24 !important;
  /* border-radius: 8px; */
  border-radius: 4px;
  border-left: 4px solid #024c87 !important;
  background: #bfd2e1 !important;
}
.react-calendar__tile--hasActive.react-calendar__tile--now {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px 0px;
}
.react-calendar__tile--hasActive.react-calendar__tile--now abbr {
  padding: 5px 10px ;
  border: 2px dashed #024c87;
  border-radius: 50%;
}

.react-calendar__navigation__next2-button,
.react-calendar__navigation__prev2-button {
  display: none;
}

.react-calendar__tile--now {
  color: #1C1E24 !important;
  /* border-radius: 8px; */
  border-radius: 4px;
  border-left: 4px solid #024c87 !important;
  background: #bfd2e1 !important;
}